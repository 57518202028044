import { Navigate, Outlet, useLocation } from "react-router-dom";
import Layout from "./layout";
import { useSelector } from "react-redux";
import { selectUser } from "../features/authSlice";
export function RequireAuth({ allowed }) {
  const { isAuthenticated } = useSelector(selectUser);
  let location = useLocation();
  if (!isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return <Layout />;
}
export function PublicRoutes() {
  const { isAuthenticated } = useSelector(selectUser);
  let location = useLocation();
  if (!isAuthenticated) {
    return <Outlet />;
  }
  return <Navigate to="/dashboard" state={{ from: location }} replace />;
}
