import React from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Nav,
  Row,
  Tab,
} from "react-bootstrap";
import {
  BsCalendar2EventFill,
  BsCameraVideoFill,
  BsChatDotsFill,
  BsStarFill,
  BsStarHalf,
  BsUpload,
} from "react-icons/bs";
import ReactStars from "react-rating-stars-component";

const Profile = () => {
  const tabs = [
    { id: 1, name: "Profile" },
    { id: 2, name: "Edit Profile" },
    { id: 3, name: "Change Password" },
    { id: 4, name: "Activity" },
  ];

  const proinput = [
    {
      id: 1,
      name: "No. Of Keywords:",
      value: "3",
    },
    {
      id: 2,
      name: "Storage Limit:",
      value: "4",
    },
    {
      id: 3,
      name: "Price:",
      value: "$855.8 / Month",
    },
    {
      id: 4,
      name: "Email:",
      value: "ahmad74@gmail.com",
    },
    {
      id: 5,
      name: "Contact:",
      value: "(91) 4544 565 456",
    },
  ];

  const edittab = [
    {
      id: 1,
      col: 4,
      title: "Add New Profile",
      edittab2: [
        {
          id: 1,
          edittab3: [
            {
              id: 1,
              lable: "Facebook Url:",
            },
            {
              id: 2,
              lable: "Twitter Url:",
            },
            {
              id: 3,
              lable: "Instagram Url:",
            },
            {
              id: 4,
              lable: "Linkedin Url:",
            },
          ],
        },
      ],
    },
    {
      id: 2,
      col: 8,
      title: "Edit Your Information",
      edittab4: [
        {
          edittab3: [
            {
              id: 1,
              lable: "First Name:",
              col: 6,
              edittab5: [
                {
                  id: 1,
                  type: "text",
                },
              ],
            },
            {
              id: 2,
              col: 6,
              lable: "Last Name:",
              edittab5: [
                {
                  id: 1,
                  type: "text",
                },
              ],
            },
            {
              id: 3,
              col: 6,
              lable: "Street Address 1:",
              edittab5: [
                {
                  id: 1,
                  type: "text",
                },
              ],
            },
            {
              id: 4,
              col: 6,
              lable: "Street Address 2:",
              edittab5: [
                {
                  id: 1,
                  type: "text",
                },
              ],
            },
            {
              id: 5,
              col: 12,
              lable: "Company Name:",
              edittab5: [
                {
                  id: 1,
                  type: "text",
                },
              ],
            },
            {
              id: 6,
              col: 12,
              lable: "Country:",
              edittab6: [1],
            },
            {
              id: 7,
              col: 6,
              lable: "Mobile Number:",
              edittab5: [
                {
                  id: 1,
                  type: "number",
                },
              ],
            },
            {
              id: 8,
              col: 6,
              lable: "Alternate Contact:",
              edittab5: [
                {
                  id: 1,
                  type: "number",
                },
              ],
            },
            {
              id: 9,
              col: 6,
              lable: "Email:",
              edittab5: [
                {
                  id: 1,
                  type: "email",
                },
              ],
            },
            {
              id: 10,
              col: 6,
              lable: "Pin Code:",
              edittab5: [
                {
                  id: 1,
                  type: "text",
                },
              ],
            },
            {
              id: 11,
              col: 12,
              lable: "Town/City:",
              edittab5: [
                {
                  id: 1,
                  type: "text",
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const timeline = [
    {
      id: 1,
      class: "border-primary text-primary",
      title: "Client Login",
      date: "24 March 2020",
      text: "Bonbon macaroon jelly beans gummi bears jelly lollipop apple",
    },
    {
      id: 2,
      class: "border-success text-success",
      title: "Scheduled Maintenance",
      date: "25 November 2021",
      text: "Bonbon macaroon jelly beans gummi bears jelly lollipop apple",
    },
    {
      id: 3,
      class: "border-danger text-danger",
      title: "Dev Meetup",
      date: "24 November 2019",
      text: (
        <>
          Bonbon macaroon jelly beans{" "}
          <span className="text-primary">gummi bears</span> jelly lollipop apple
        </>
      ),
      line2: [1],
    },
    {
      id: 4,
      class: "border-primary text-primary",
      title: "Client Call",
      date: "25 November 2021",
      text: "Bonbon macaroon jelly beans gummi bears jelly lollipop apple",
    },
    {
      id: 5,
      class: "border-warning text-warning",
      title: "Mega event",
      date: "25 June 2022",
      text: "Bonbon macaroon jelly beans gummi bears jelly lollipop apple",
    },
  ];

  const ratingChanged = (newRating) => {
    console.log(`Total Stars: is ${newRating}`);
  };

  return (
    <>
      <Container fluid className="mt-n5">
        <Tab.Container id="Profile-tabs" defaultActiveKey="1">
          <Row>
            <Col md={12}>
              <Card data-aos={"fade-up"}>
                <Card.Body className="py-2">
                  <div className="d-flex flex-wrap align-items-center justify-content-between">
                    <div className="d-flex flex-wrap gap-md-0 gap-4 align-items-center">
                      <div className="position-relative me-3 profile-logo profile-logo1">
                        <img
                          src="http://localhost/admin/assets/images/avatars/01.png"
                          alt="User-Profile"
                          className="img-fluid rounded-pill avatar-40"
                        />
                      </div>
                      <div className="d-flex flex-wrap align-items-center mb-3 mb-sm-0">
                        <h4 className="me-2 h4">Altaf Ahmad</h4>
                        <span> - Web Designer</span>
                      </div>
                    </div>
                    <Nav variant="pills" className="bg-soft-primary mb-0">
                      {tabs.map((tab, idx) => (
                        <Nav.Item key={idx}>
                          <Nav.Link
                            className="ma-btn-primary"
                            eventKey={tab.id}
                          >
                            {tab.name}
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={12}>
              <Tab.Content>
                <Tab.Pane eventKey="1">
                  <Row>
                    <Col md={4}>
                      <Card
                        className="h-100"
                        data-aos={"fade-up"}
                        data-aos-delay={200}
                      >
                        <Card.Body>
                          <div className="d-flex align-items-center justify-content-center">
                            <div className="d-flex flex-column text-center align-items-center justify-content-between ">
                              <div className="fs-italic">
                                <h5>Altaf Ahmad</h5>
                                <div className="text-muted-50 mb-1">
                                  <small>Website Designer</small>
                                </div>
                              </div>
                              <div className="text-black-50 text-warning">
                                <ReactStars
                                  count={5}
                                  onChange={ratingChanged}
                                  size={24}
                                  isHalf={true}
                                  emptyIcon={<BsStarFill />}
                                  halfIcon={<BsStarHalf />}
                                  fullIcon={<BsStarFill />}
                                  activeColor="#f16a1b"
                                />
                              </div>
                              <div className="card-profile-progress">
                                <div
                                  id="circle-progress-1"
                                  className="circle-progress  circle-progress-basic circle-progress-primary"
                                >
                                  <svg
                                    version="1.1"
                                    width={100}
                                    height={100}
                                    viewBox="0 0 100 100"
                                    className="circle-progress"
                                  >
                                    <circle
                                      className="circle-progress-circle"
                                      cx={50}
                                      cy={50}
                                      r={46}
                                      fill="none"
                                      stroke="#ddd"
                                      strokeWidth={8}
                                    />
                                    <path
                                      d="M 50 4 A 46 46 0 1 1 6.25140025042294 35.78521825875241"
                                      className="circle-progress-value"
                                      fill="none"
                                      stroke="#5addfc"
                                      strokeWidth={8}
                                    />
                                    <text
                                      className="circle-progress-text"
                                      x={50}
                                      y={50}
                                      font="16px Arial, sans-serif"
                                      textAnchor="middle"
                                      fill="#999"
                                      dy="0.4em"
                                    >
                                      80%
                                    </text>
                                  </svg>
                                </div>
                                <img
                                  src="http://localhost/admin/assets/images/avatars/01.png"
                                  alt="User-Profile"
                                  className="img-fluid rounded-circle card-img"
                                />
                              </div>
                              <div className="mt-3 text-center text-muted-50">
                                <p>
                                  Slate helps you see how many more days you
                                  need
                                </p>
                              </div>
                              <div className="d-flex icon-pill">
                                <a
                                  href="#"
                                  className="btn btn-sm rounded-pill p-2 ms-2"
                                >
                                  <BsCalendar2EventFill className="text-primary fs-6" />
                                </a>
                                <a
                                  href="#"
                                  className="btn btn-sm rounded-pill p-2 ms-2"
                                >
                                  <BsCameraVideoFill className="text-danger fs-6" />
                                </a>
                                <a
                                  href="#"
                                  className="btn btn-sm rounded-pill p-2 ms-2"
                                >
                                  <BsChatDotsFill className="text-success fs-6" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={8}>
                      <Card
                        className="h-100"
                        data-aos={"fade-up"}
                        data-aos-delay={300}
                      >
                        <Card.Header>
                          <Card.Title>About User</Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <div className="user-bio">
                            <p>
                              Tart I love sugar plum I love oat cake. Sweet roll
                              caramels I love jujubes. Topping cake wafer.
                            </p>
                          </div>
                          {proinput.map((input, ida) => (
                            <div key={ida} className="mt-2">
                              <h6 className="mb-1">{input.name}</h6>
                              <Form.Control
                                defaultValue={input.value}
                                disabled
                              />
                            </div>
                          ))}
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="2">
                  <Row>
                    {edittab.map((edit, ibb) => (
                      <Col md={edit.col}>
                        <Card className="h-100" data-aos={"fade-up"}>
                          <Card.Header>
                            <Card.Title>{edit.title}</Card.Title>
                          </Card.Header>
                          <Card.Body>
                            {edit.edittab2?.length > 0 &&
                              edit.edittab2?.map((icb) => (
                                <Form key={icb} className="row g-3">
                                  <Col md={12}>
                                    <div className="profile-img-edit position-relative">
                                      <img
                                        src="http://localhost/admin/assets/images/avatars/01.png"
                                        id="output"
                                        alt="profile-pic"
                                        className="profile-pic rounded avatar-100"
                                      />
                                      <div className="upload-icone bg-primary">
                                        <label
                                          htmlFor="img-file"
                                          className="cursor-pointer"
                                        >
                                          <BsUpload className="text-white fs-6" />
                                        </label>
                                        <Form.Control
                                          id="img-file"
                                          className="d-none"
                                          type="file"
                                        />
                                      </div>
                                    </div>
                                    <div className="img-extension mt-3">
                                      <div className="d-inline-block align-items-center">
                                        <span>Only </span>
                                        <a href="javascript:void();">
                                          .jpg
                                        </a>{" "}
                                        <a href="javascript:void();">.png</a>{" "}
                                        <a href="javascript:void();">.jpeg</a>
                                        <span> allowed</span>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md={12}>
                                    <Form.Label>User Role:</Form.Label>
                                    <Form.Select aria-label="Default select example">
                                      <option>Web Designer</option>
                                      <option>Web Developer</option>
                                      <option>Tester</option>
                                      <option>Php Developer</option>
                                      <option>Ios Developer </option>
                                    </Form.Select>
                                  </Col>
                                  {icb.edittab3?.length > 0 &&
                                    icb.edittab3?.map((form, icp) => (
                                      <Col key={icp} md={12}>
                                        <Form.Label>{form.lable}</Form.Label>
                                        <Form.Control type="url" />
                                      </Col>
                                    ))}
                                </Form>
                              ))}
                            {edit.edittab4?.length > 0 &&
                              edit.edittab4?.map((icb) => (
                                <Form key={icb} className="row g-3">
                                  {icb.edittab3?.map((form2, icp) => (
                                    <Col key={icp} md={form2.col}>
                                      <Form.Label>{form2.lable}</Form.Label>
                                      {form2.edittab5?.length > 0 &&
                                        form2.edittab5?.map((form2, icb) => (
                                          <Form.Control type={form2.type} />
                                        ))}
                                      {form2.edittab6?.length > 0 &&
                                        form2.edittab6?.map((icb) => (
                                          <Form.Select aria-label="Default select example">
                                            <option>Select Country</option>
                                            <option value="1">USA</option>
                                            <option value="2">Noida</option>
                                            <option value="3">India</option>
                                          </Form.Select>
                                        ))}
                                    </Col>
                                  ))}
                                  <Button
                                    variant="primary"
                                    className="ma-btn-primary w-auto"
                                  >
                                    Save Profile
                                  </Button>
                                </Form>
                              ))}
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="3">
                  <Card>
                    <Card.Header>
                      <Card.Title>Security</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Morbi vulputate, ex ac venenatis mollis, diam nibh
                        finibus leo
                      </p>

                      <Form className="form-horizontal">
                        <Form.Group className="row">
                          <Form.Label className="col-sm-3 align-self-center mb-0">
                            New Password:
                          </Form.Label>
                          <Col md={9}>
                            <Form.Control
                              type="password"
                              placeholder="New Password"
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group className="row my-3">
                          <Form.Label className="col-sm-3 align-self-center mb-0">
                            Confirm Password:
                          </Form.Label>
                          <Col md={9}>
                            <Form.Control
                              type="password"
                              placeholder="Confirm Password"
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group>
                          <Form.Check
                            type="checkbox"
                            id="chk"
                            label="Remember me"
                          />
                        </Form.Group>
                        <hr />
                        <Button variant="primary" className="ma-btn-primary">
                          Change Password
                        </Button>
                      </Form>
                    </Card.Body>
                  </Card>
                </Tab.Pane>
                <Tab.Pane eventKey="4">
                  <Card>
                    <Card.Header>
                      <Card.Title>Security</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <div className="iq-timeline0 m-0 d-flex align-items-center justify-content-between position-relative">
                        <ul className="list-inline p-0 m-0">
                          {timeline.map((line, iii) => (
                            <>
                              <li key={iii}>
                                <div
                                  className={`timeline-dots timeline-dot1 ${line.class}`}
                                ></div>
                                <h6 className="float-left mb-1">
                                  {line.title}
                                </h6>
                                <small className="float-right mt-1">
                                  {line.date}
                                </small>
                                <div className="d-inline-block w-100">
                                  <p>{line.text}</p>
                                </div>
                                {line.line2?.length > 0 &&
                                  line.line2?.map((ioi) => (
                                    <div
                                      key={ioi}
                                      className="iq-media-group iq-media-group-1"
                                    >
                                      <a href="#" className="iq-media-1">
                                        <div className="icon iq-icon-box-3 rounded-pill">
                                          SP
                                        </div>
                                      </a>
                                      <a href="#" className="iq-media-1">
                                        <div className="icon iq-icon-box-3 rounded-pill">
                                          PP
                                        </div>
                                      </a>
                                      <a href="#" className="iq-media-1">
                                        <div className="icon iq-icon-box-3 rounded-pill">
                                          MM
                                        </div>
                                      </a>
                                    </div>
                                  ))}
                              </li>
                            </>
                          ))}
                        </ul>
                      </div>
                    </Card.Body>
                  </Card>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </>
  );
};

export default Profile;
