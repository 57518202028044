import React from "react";
import { Table } from "react-bootstrap";

const KeywordsTable = ({ data }) => {
  return (
    <>
      <div className="d-flex">
        <Table size="sm" style={{ fontSize: "14px" }} bordered hover>
          <tbody>
            <tr>
              <th className="ps-3">Title Tag </th>
              <td>{data.title_tag ? data.title_tag : "-"}</td>
            </tr>
            <tr>
              <th className="ps-3">Twitter Description </th>
              <td>
                {data.twitter_description ? data.twitter_description : "-"}
              </td>
            </tr>
            <tr>
              <th className="align-middle ps-3">Twitter Image </th>
              <td>
                {data.twitter_image ? (
                  <img
                    width={40}
                    height={40}
                    className="rounded"
                    style={{ objectFit: "cover" }}
                    src={`${process.env.REACT_APP_API_URL}/${data.twitter_image}`}
                  />
                ) : (
                  "-"
                )}
              </td>
            </tr>
            <tr>
              <th className="ps-3">Twitter Title </th>
              <td>{data.twitter_title ? data.twitter_title : "-"}</td>
            </tr>

            <tr>
              <th className="ps-3">Region </th>
              <td>{data.region ? data.region : "-"}</td>
            </tr>

            <tr>
              <th className="ps-3">Copyright </th>
              <td>{data.copyright ? data.copyright : "-"}</td>
            </tr>

            <tr>
              <th className="ps-3">Classification </th>
              <td>{data.classification ? data.classification : "-"}</td>
            </tr>
          </tbody>
        </Table>
        <Table size="sm" style={{ fontSize: "14px" }} bordered hover>
          <tbody>
            <tr>
              <th className="ps-3">Og Descriptions </th>
              <td>{data.og_description ? data.og_description : "-"}</td>
            </tr>
            <tr>
              <th className="ps-3 align-middle">Og Image </th>
              <td>
                {data.og_image ? (
                  <img
                    width={40}
                    height={40}
                    className="rounded"
                    style={{ objectFit: "cover" }}
                    src={`${process.env.REACT_APP_API_URL}/${data.og_image}`}
                  />
                ) : (
                  "-"
                )}
              </td>
            </tr>
            <tr>
              <th className="ps-3">Og Title </th>
              <td>{data.og_title ? data.og_title : "-"}</td>
            </tr>
            <tr>
              <th className="ps-3">Og URL </th>
              <td>{data.og_url ? data.og_url : "-"}</td>
            </tr>

            <tr>
              <th className="ps-3">Og Type </th>
              <td>{data.og_type ? data.og_type : "-"}</td>
            </tr>
            <tr>
              <th className="ps-3">Twitter Site </th>
              <td>{data.twitter_site ? data.twitter_site : "-"}</td>
            </tr>
            <tr>
              <th className="ps-3">Twitter Domain</th>
              <td>{data.twitter_domain ? data.twitter_domain : "-"}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default KeywordsTable;
