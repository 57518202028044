import axios from "axios";

const customApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL_LIVE,
  headers: {
    email: process.env.REACT_APP_API_EMAIL,
    password: process.env.REACT_APP_API_PASSWORD,
  },
});

customApi.interceptors.request.use(function (config) {
  let token = localStorage.getItem("token");
  config.headers["Authorization"] = `Bearer ${token}`;
  return config;
});

export const updatePlugin = async (values) => {
  try {
    const { data } = await customApi.post(
      "/api/updatepartneroneclient",
      values
    );
    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const updateData = async (values) => {
  try {
    const { data } = await customApi.post("/api/updatefile", values);
    return data;
  } catch (error) {
    return error.response.data;
  }
};
export const updateSocialAndNoti = async (values) => {
  try {
    const { data } = await customApi.post(
      "/api/updatepluginsocialandnoti",
      values
    );
    return data;
  } catch (error) {
    return error.response.data;
  }
};
export const getScriptData = async (values) => {
  try {
    const { data } = await customApi.post(`/api/getpluginscript`, values);
    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const changePassword = async (udata) => {
  try {
    const { data } = await customApi.post("/api/updatepluginpassword", udata);
    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const getAllInquiries = async (val) => {
  try {
    const { data } = await customApi.post(`/api/getenquiry`, val);
    return data;
  } catch (error) {
    return error.response.data;
  }
};
export const getAllDashboardData = async (val) => {
  try {
    const { data } = await customApi.post(`/api/get-dashboard`, val);
    return data;
  } catch (error) {
    return error.response.data;
  }
};
export const getUpdateUrl = async (id) => {
  try {
    const { data } = await customApi.get(`/api/allurls/${id}`);
    return data;
  } catch (error) {
    return error.response.data;
  }
};
export const getPostsAndUpdate = async (id) => {
  try {
    const { data } = await customApi.get(`/api/getpostandupdates/${id}`);
    return data;
  } catch (error) {
    return error.response.data;
  }
};
export const addPostsAndUpdates = async (udata) => {
  try {
    const { data } = await customApi.post("/api/addnewpost", udata);
    return data;
  } catch (error) {
    return error.response.data;
  }
};
export const updatePostsAndUpdates = async (udata) => {
  try {
    const { data } = await customApi.post("/api/updatepost", udata);
    return data;
  } catch (error) {
    return error.response.data;
  }
};
export const deletePosts = async (id) => {
  try {
    const { data } = await customApi.get(`/api/deletepost/${id}`);
    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteMultiplePosts = async (values) => {
  try {
    const { data } = await customApi.post("/api/deleteselectedpost", values);
    return data;
  } catch (error) {
    return error.response.data;
  }
};
export const deleteMultipleKeywords = async (values) => {
  try {
    const { data } = await customApi.post("/api/deleteselectedkeyword", values);
    return data;
  } catch (error) {
    return error.response.data;
  }
};
export const getKeywordsData = async (values) => {
  try {
    const { data } = await customApi.post("/api/getkeywordbyuser", values);
    return data;
  } catch (error) {
    return error.response.data;
  }
};
export const addKeywordsData = async (values) => {
  try {
    const { data } = await customApi.post("/api/setkeywordbyurl", values);
    return data;
  } catch (error) {
    return error.response.data;
  }
};
export const deleteKeywords = async (values) => {
  try {
    const { data } = await customApi.post("/api/deletekeywordbyid", values);
    return data;
  } catch (error) {
    return error.response.data;
  }
};
export const getGeneratesitemap = async () => {
  try {
    const { data } = await customApi.get("/api/generatesitemap");
    return data;
  } catch (error) {
    return error.response.data;
  }
};
export const getAllVisitGraph = async () => {
  try {
    const { data } = await customApi.get("/api/visit-graph");
    return data;
  } catch (error) {
    return error.response.data;
  }
};
