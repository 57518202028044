import React from "react";
import { Card, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { BsTools } from "react-icons/bs";
import _ from "lodash";
import HomeCms from "../components/CMS-Settings/HomeCms";
import AboutCms from "../components/CMS-Settings/AboutCms";
import AllServicesCms from "../components/CMS-Settings/AllServicesCms";
import PricingCms from "../components/CMS-Settings/PricingCms";
import BlogCms from "../components/CMS-Settings/BlogCms";
import FeaturedServicesCms from "../components/CMS-Settings/FeaturedServicesCms";
import NavbarCms from "../components/CMS-Settings/NavbarCms";
import FooterCms from "../components/CMS-Settings/FooterCms";
import SeoWorkCarousel from "../components/CMS-Settings/SeoWorkCarousel";

const CmsSetting = () => {
    const tabs = [
        { id: 1, name: "Navbar" },
        { id: 2, name: "Home" },
        { id: 3, name: "About" },
        { id: 4, name: "Featured Services" },
        { id: 5, name: "All Services" },
        { id: 6, name: "Pricing" },
        { id: 7, name: "Blog" },
        { id: 8, name: "Seo Work & Carousel" },
        { id: 9, name: "Footer" },
    ];

    return (
        <>
            <Container fluid className="mt-n5 mb-3">
                <Tab.Container id="Profile-tabs" defaultActiveKey="1">
                    <Row>
                        <Col md={12}>
                            <Card data-aos={"fade-up"}>
                                <Card.Body className="py-2">
                                    <div className="d-md-flex flex-wrap align-items-center justify-content-between">
                                        <div className="d-flex flex-wrap gap-md-0 gap-4 align-items-center">
                                            <div className="position-relative me-3 profile-logo profile-logo1">
                                                <BsTools className="fs-2" />
                                            </div>
                                            <div className="d-flex flex-wrap align-items-center mb-3 mb-sm-0">
                                                <h4 className="me-2 h4">CMS</h4>
                                                <span> - General Settings</span>
                                            </div>
                                        </div>
                                        <Nav variant="pills" className="bg-soft-primary d-md-inline-flex d-block rounded-4 mb-0">
                                            {tabs.map((tab, idx) => (
                                                <Nav.Item key={idx}>
                                                    <Nav.Link
                                                        className="ma-btn-primary"
                                                        eventKey={tab.id}>
                                                        {tab.name}
                                                    </Nav.Link>
                                                </Nav.Item>
                                            ))}
                                        </Nav>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={12}>
                            <Tab.Content>
                                <Tab.Pane eventKey={"1"}>
                                    <NavbarCms />
                                </Tab.Pane>
                                <Tab.Pane eventKey={"2"}>
                                    <HomeCms />
                                </Tab.Pane>
                                <Tab.Pane eventKey={"3"}>
                                    <AboutCms />
                                </Tab.Pane>
                                <Tab.Pane eventKey={"4"}>
                                    <FeaturedServicesCms />
                                </Tab.Pane>
                                <Tab.Pane eventKey={"5"}>
                                    <AllServicesCms />
                                </Tab.Pane>
                                <Tab.Pane eventKey={"6"}>
                                    <PricingCms />
                                </Tab.Pane>
                                <Tab.Pane eventKey={"7"}>
                                    <BlogCms />
                                </Tab.Pane>
                                <Tab.Pane eventKey={"8"}>
                                    <SeoWorkCarousel />
                                </Tab.Pane>
                                <Tab.Pane eventKey={"9"}>
                                    <FooterCms />
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>
        </>
    );
};

export default CmsSetting;
