import React from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { BsArrowLeft } from "react-icons/bs";
import { Link } from "react-router-dom";

const OTP = () => {
  return (
    <>
      <Container>
        <Row className="justify-content-center align-items-center min-vh-100">
          <Col md={11}>
            <Row className="gx-0">
              <Col md={3} className="mx-auto">
                <Card
                  className="h-100 text-center px-md-3 rounded-0 flex-row-reverse align-items-center rounded-end"
                  data-aos={"fade-up"}
                >
                  <Card.Body>
                    <h3>Verify with OTP</h3>
                    <small>
                      Enter the OTP Send to <b>+91-2254121462</b>
                    </small>
                    <Form className="myform otpContainer justify-content-between row g-4 mt-1">
                      {["otp0", "otp1", "otp2", "otp3"].map((OTP) => (
                        <Form.Control
                          className="p-2"
                          type="tel"
                          name={OTP}
                          defaultValue=""
                          maxLength={1}
                          data-val={OTP}
                          autocomplete="off"
                        />
                      ))}
                      <small className="mt-1 d-non">
                        {" "}
                        Don't receive the OTP ?{" "}
                        <b className="text-primary">Resend OTP</b>
                      </small>
                      <Button
                        type="submit"
                        className="mb-3 w-100 btn-shadow ma-btn-primary"
                        variant="primary"
                      >
                        Verify & Proceed
                      </Button>
                    </Form>
                    <p className="mb-0 text-center">
                      <Link to="/" className="text-underline">
                        <BsArrowLeft className="fs-4" /> Back to login
                      </Link>
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OTP;
