import React, { useState } from "react";
import { Col, Card, Row, Button, Form, FloatingLabel } from "react-bootstrap";
import { BsPlusCircleFill } from "react-icons/bs";


const SeoWorkCarousel = () => {
  const [count, setCount] = useState(0)
  const AddedElement = () =>
    <>
      <FloatingLabel controlId="face2" label="Keyword Title">
        <Form.Control type="url" defaultValue="" />
      </FloatingLabel>
      <FloatingLabel controlId="face2" label="Keyword Description" className="mb-3">
        <Form.Control type="url" className="border-top-0" defaultValue="" />
      </FloatingLabel>
    </>

  const [count2, setCount2] = useState(0)
  const AddedElement2 = () =>
    <>
      <FloatingLabel controlId="face2" label="Carousel Description">
        <Form.Control type="url" className="border-bottom-0" defaultValue="" />
      </FloatingLabel>
      <FloatingLabel controlId="face2" label="Carousel Image">
        <Form.Control type="file" className="border-bottom-0" />
      </FloatingLabel>
      <FloatingLabel controlId="face2" label="Carousel Name">
        <Form.Control type="url" className="border-bottom-0" defaultValue="" />
      </FloatingLabel>
      <FloatingLabel controlId="face2" label="Carousel Caption" className="mb-3">
        <Form.Control type="url" defaultValue="" />
      </FloatingLabel>
    </>

  const seo = [
    {
      id: 1,
      title: 'Keyword Research',
      description: 'Effective keywords are the foundation of a great SEO campaign because they give us insight into your consumers’ questions and concerns.',
    },
    {
      id: 2,
      title: 'Indutry Analysis',
      description: 'There are a lot of companies trying to rank for your keywords. We’ll analyze their strengths and weaknesses to find new opportunities for you.',
    },
    {
      id: 3,
      title: 'Off-site Optimization',
      description: 'Building links from established sites remains a constant in good SEO. The most effective links will contribute to your rankings and drive quality traffic to your site.',
    },
    {
      id: 4,
      title: 'Tracking and Evolving',
      description: 'We examine all the relevant metrics and data to constantly refine our approach and ensure we are targeting the right audience at the right time. We can then adapt our strategy as needed.',
    },
  ]

  const carousel = [
    {
      id: 1,
      name: 'Keyword Research',
      description2: 'Effective keywords are the foundation of a great SEO campaign because they give us insight into your consumers’ questions and concerns.',
    },
    {
      id: 2,
      name: 'Indutry Analysis',
      description2: 'There are a lot of companies trying to rank for your keywords. We’ll analyze their strengths and weaknesses to find new opportunities for you.',
    },
    {
      id: 3,
      name: 'Off-site Optimization',
      description2: 'Building links from established sites remains a constant in good SEO. The most effective links will contribute to your rankings and drive quality traffic to your site.',
    },
    {
      id: 4,
      name: 'Tracking and Evolving',
      description2: 'We examine all the relevant metrics and data to constantly refine our approach and ensure we are targeting the right audience at the right time. We can then adapt our strategy as needed.',
    },
  ]

  return (
    <>
      <Row>
        <Col md={12}>
          <Card data-aos={"fade-up"} data-aos-delay={200}>
            <Card.Body>
              <Form>
                <Row className="align-items-end">
                  <Col>
                    <Form.Label>Title</Form.Label>
                    <Form.Control defaultValue="Our SEO Work" />
                  </Col>
                  <Col>
                    <Form.Label>Heading</Form.Label>
                    <Form.Control defaultValue="How Does SEO Work?" />
                  </Col>
                  <Col>
                    <Button variant="outline-primary" className="ma-btn-primary">Save</Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col md={12}>
          <Card data-aos={"fade-up"} data-aos-delay={200}>
            <Card.Header className="pb-0">
              <Card.Title>-- Manage SEO Work & Carousel Content --</Card.Title>
            </Card.Header>
            <Card.Body>
              <Form>
                <Row className="align-item-end">
                  <Col>
                    <Form.Label>SEO Work</Form.Label>
                    <FloatingLabel controlId="sec2" label="Title" className="mb-3">
                      <Form.Control type="text" defaultValue="Common Local SEO questions you might have?" />
                    </FloatingLabel>
                    <div className="my-scroll">
                      {seo.map((work, idf) => (
                        <div key={idf}>
                          <FloatingLabel controlId="face2" label="Keyword Title">
                            <Form.Control type="url" defaultValue={work.title} />
                          </FloatingLabel>
                          <FloatingLabel controlId="face2" label="Keyword Description" className="mb-3">
                            <Form.Control type="url" className="border-top-0" defaultValue={work.description} />
                          </FloatingLabel>
                        </div>
                      ))}
                      {[...Array(count)].map((_, i) => <AddedElement key={i} />)}
                      <div className="text-end fs-3 a-btn-primary">
                        <BsPlusCircleFill onClick={() => setCount(count + 1)} />
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <Form.Label>Carousel</Form.Label>
                    <div className="my-scroll">
                      {carousel.map((slide, idp) => (
                        <div key={idp}>
                          <FloatingLabel controlId="face2" label="Carousel Description">
                            <Form.Control type="url" className="border-bottom-0" defaultValue={slide.description2} />
                          </FloatingLabel>
                          <FloatingLabel controlId="face2" label="Carousel Image">
                            <Form.Control type="file" className="border-bottom-0" />
                          </FloatingLabel>
                          <FloatingLabel controlId="face2" label="Carousel Name">
                            <Form.Control type="url" className="border-bottom-0" defaultValue={slide.name} />
                          </FloatingLabel>
                          <FloatingLabel controlId="face2" label="Carousel Caption" className="mb-3">
                            <Form.Control type="url" defaultValue={slide.name} />
                          </FloatingLabel>
                        </div>
                      ))}
                      {[...Array(count2)].map((_, i) => <AddedElement2 key={i} />)}
                      <div className="text-end fs-3 a-btn-primary">
                        <BsPlusCircleFill onClick={() => setCount2(count + 1)} />
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="text-end">
                  <Button variant="primary" type={"submit"} className="ma-btn-primary">Save Change</Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SeoWorkCarousel;
