import { ErrorMessage, Field, Formik } from "formik";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { selectUser } from "../../features/authSlice";
import { addKeywordsData, getUpdateUrl } from "../../Services/adminApi";
import { keywordSchema } from "../../utils/validationSchemas";

const AddData = ({ close, edit, open }) => {
  const notify = (message) => {
    toast(message);
  };
  const { user } = useSelector(selectUser);
  const [selectedFile, setSelectedFile] = useState(null);
  const [twitterImageFile, setTwitterImageFile] = useState(null);
  const [postAndUpdates, setPostAndUpdates] = useState([]);
  const [tags, setTags] = useState({
    title: [],
    description: [],
    keywords: [],
  });

  const handleSubmit = async (values, setSubmitting) => {
    const formData = new FormData();

    Object.entries(values).forEach(([name, value]) => {
      if (
        name !== "title_tag" &&
        name !== "description_tag" &&
        name !== "keywords_tag"
      ) {
        formData.append(name, value);
      }
    });

    function concatenateTags(tagArray) {
      return tagArray
        ? tagArray.map((item) => item.label || item).join(" | ")
        : "";
    }

    formData.append("title_tag", concatenateTags(tags.title));
    formData.append("description_tag", concatenateTags(tags.description));
    formData.append("keywords_tag", concatenateTags(tags.keywords));

    if (edit.id) {
      formData.append("id", edit.id);
    } else {
      formData.append("created_by", user.login_id || user.id);
    }

    // return console.log("formData", ...formData);

    const res = await addKeywordsData(formData);

    if (res.status) {
      notify(res.message);
      close();
    } else {
      notify(res.message);
    }

    setSubmitting(false);
  };

  const fetchData = async () => {
    const res = await getUpdateUrl(user.login_id || user.id);
    if (res.status) {
      setPostAndUpdates(res.data);
    } else {
      setPostAndUpdates([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Modal
      size="xl"
      scrollable={true}
      show={open}
      onHide={close}
      centered
      backdrop="static"
      className="my-modal"
    >
      <Modal.Header className="bg-soft-primary" onClick={close} closeButton>
        <Modal.Title className="text-primary">
          {edit.id ? "Edit" : "Add"} Keyword
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {" "}
        <Formik
          validationSchema={keywordSchema}
          enableReinitialize={true}
          initialValues={{
            keyword_url: edit.keyword_url || "",
            og_url: edit.og_url || "",
            og_title: edit.og_title || "",
            twitter_title: edit.twitter_title || "",
            og_image: edit.og_image || null,
            twitter_image: edit.twitter_image || null,
            og_description: edit.og_description || "",
            twitter_description: edit.twitter_description || "",
            region: edit.region || "",
            copyright: edit.copyright || "",
            classification: edit.classification || "",
            og_type: edit.og_type || "",
            twitter_site: edit.twitter_site || "",
            twitter_domain: edit.twitter_domain || "",
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            handleSubmit(values, setSubmitting, resetForm);
          }}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form className="p-2" noValidate onSubmit={handleSubmit}>
              <Row>
                <Form.Group className="mb-3">
                  <Form.Select
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.keyword_url}
                    name={"keyword_url"}
                    aria-label="Default select example"
                  >
                    <option>--Select--</option>
                    {postAndUpdates.map((ele) => (
                      <option key={ele.id}>{ele.url}</option>
                    ))}
                  </Form.Select>
                  <small className="text-danger">
                    {errors.keyword_url &&
                      touched.keyword_url &&
                      errors.keyword_url}
                  </small>
                </Form.Group>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3 row">
                    <Form.Label className="col-md-3 align-self-center mb-0">
                      Og Url <span className="text-danger">*</span>
                    </Form.Label>
                    <Col md={9}>
                      <Field
                        as={Form.Control}
                        name="og_url"
                        type="url"
                        placeholder="Type Here..."
                      />
                      <ErrorMessage
                        name="og_url"
                        className="text-danger"
                        component={"small"}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3 row">
                    <Form.Label className="col-md-3 align-self-center mb-0">
                      Title Tag
                    </Form.Label>
                    <Col md={9}>
                      <Typeahead
                        className="text-shadow-1"
                        allowNew
                        // defaultInputValue={values.title_tag}
                        onChange={(selected) => {
                          setTags({ ...tags, title: selected });
                        }}
                        id="custom-selections-example"
                        multiple
                        newSelectionPrefix="Add a new tag: "
                        options={[]}
                        defaultSelected={
                          edit.id
                            ? edit.title_tag
                                .split("|")
                                .filter((element) => element)
                            : []
                        }
                        placeholder="Type here..."
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3 row">
                    <Form.Label className="col-md-3 align-self-center mb-0">
                      Description Tag
                    </Form.Label>
                    <Col md={9}>
                      <Typeahead
                        className="text-shadow-1"
                        allowNew
                        onChange={(selected) => {
                          setTags({ ...tags, description: selected });
                        }}
                        id="custom-selections-example"
                        multiple
                        newSelectionPrefix="Add a new tag: "
                        options={[]}
                        defaultSelected={
                          edit.id
                            ? edit.description_tag
                                .split("|")
                                .filter((element) => element)
                            : []
                        }
                        placeholder="Type here..."
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3 row">
                    <Form.Label className="col-md-3 align-self-center mb-0">
                      Keyword Tag
                    </Form.Label>
                    <Col md={9}>
                      <Typeahead
                        className="text-shadow-1"
                        allowNew
                        onChange={(selected) => {
                          setTags({ ...tags, keywords: selected });
                        }}
                        id="custom-selections-example"
                        multiple
                        newSelectionPrefix="Add a new tag: "
                        options={[]}
                        defaultSelected={
                          edit.id
                            ? edit.keywords_tag
                                .split("|")
                                .filter((element) => element)
                            : []
                        }
                        placeholder="Type here..."
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3 row">
                    <Form.Label className="col-md-3 align-self-center mb-0">
                      Og Title <span className="text-danger">*</span>
                    </Form.Label>
                    <Col md={9}>
                      <Field
                        as={Form.Control}
                        name="og_title"
                        type="text"
                        placeholder="Type Here..."
                      />
                      <ErrorMessage
                        name="og_title"
                        className="text-danger"
                        component={"small"}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3 row">
                    <Form.Label className="col-md-3 align-self-center mb-0">
                      Twitter Title <span className="text-danger">*</span>
                    </Form.Label>
                    <Col md={9}>
                      <Field
                        as={Form.Control}
                        name="twitter_title"
                        type="text"
                        placeholder="Type Here..."
                      />
                      <ErrorMessage
                        name="twitter_title"
                        className="text-danger"
                        component={"small"}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3 row">
                    <Form.Label className="col-md-3 align-self-center mb-0">
                      Og Image <span className="text-danger">*</span>
                    </Form.Label>
                    <Col md={9}>
                      <div className="d-flex align-items-center gap-2">
                        {(selectedFile || edit?.og_image) && (
                          <img
                            width={40}
                            height={40}
                            className="rounded"
                            style={{ objectFit: "cover" }}
                            src={
                              (selectedFile &&
                                selectedFile.type.startsWith("image/") &&
                                URL.createObjectURL(selectedFile)) ||
                              `${process.env.REACT_APP_API_URL}/${edit?.og_image}`
                            }
                          />
                        )}
                        <Form.Control
                          onChange={(e) => {
                            setSelectedFile(e.currentTarget.files[0]);
                            setFieldValue("og_image", e.target.files[0]);
                          }}
                          onBlur={handleBlur}
                          name="og_image"
                          type="file"
                          placeholder="Type Here..."
                        />
                      </div>
                      <ErrorMessage
                        name="og_image"
                        className="text-danger"
                        component={"small"}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3 row">
                    <Form.Label className="col-md-3 align-self-center mb-0">
                      Twitter Image <span className="text-danger">*</span>
                    </Form.Label>
                    <Col md={9}>
                      <div className="d-flex align-items-center gap-2">
                        {(twitterImageFile || edit?.twitter_image) && (
                          <img
                            width={40}
                            height={40}
                            className="rounded"
                            style={{ objectFit: "cover" }}
                            src={
                              (twitterImageFile &&
                                twitterImageFile.type.startsWith("image/") &&
                                URL.createObjectURL(twitterImageFile)) ||
                              `${process.env.REACT_APP_API_URL}/${edit?.twitter_image}`
                            }
                          />
                        )}
                        <Form.Control
                          onChange={(e) => {
                            setTwitterImageFile(e.currentTarget.files[0]);
                            setFieldValue("twitter_image", e.target.files[0]);
                          }}
                          onBlur={handleBlur}
                          name="twitter_image"
                          type="file"
                          placeholder="Type Here..."
                        />
                      </div>
                      <ErrorMessage
                        name="twitter_image"
                        className="text-danger"
                        component={"small"}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3 row">
                    <Form.Label className="col-md-3 align-self-center mb-0">
                      Og Description <span className="text-danger">*</span>
                    </Form.Label>
                    <Col md={9}>
                      <Field
                        as={Form.Control}
                        name="og_description"
                        type="text"
                        placeholder="Type Here..."
                      />
                      <ErrorMessage
                        name="og_description"
                        className="text-danger"
                        component={"small"}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3 row">
                    <Form.Label className="col-md-3 align-self-center mb-0">
                      Twitter Description <span className="text-danger">*</span>
                    </Form.Label>
                    <Col md={9}>
                      <Field
                        as={Form.Control}
                        name="twitter_description"
                        type="text"
                        placeholder="Type Here..."
                      />
                      <ErrorMessage
                        name="twitter_description"
                        className="text-danger"
                        component={"small"}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3 row">
                    <Form.Label className="col-md-3 align-self-center mb-0">
                      Region
                    </Form.Label>
                    <Col md={9}>
                      <Field
                        as={Form.Control}
                        name="region"
                        type="text"
                        placeholder="Type Here..."
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3 row">
                    <Form.Label className="col-md-3 align-self-center mb-0">
                      Copyright
                    </Form.Label>
                    <Col md={9}>
                      <Field
                        as={Form.Control}
                        name="copyright"
                        type="text"
                        placeholder="Type Here..."
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3 row">
                    <Form.Label className="col-md-3 align-self-center mb-0">
                      Classification
                    </Form.Label>
                    <Col md={9}>
                      <Field
                        as={Form.Control}
                        name="classification"
                        type="text"
                        placeholder="Type Here..."
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3 row">
                    <Form.Label className="col-md-3 align-self-center mb-0">
                      Og Type
                    </Form.Label>
                    <Col md={9}>
                      <Field
                        as={Form.Control}
                        name="og_type"
                        type="text"
                        placeholder="Type Here..."
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3 row">
                    <Form.Label className="col-md-3 align-self-center mb-0">
                      Twitter Site
                    </Form.Label>
                    <Col md={9}>
                      <Field
                        as={Form.Control}
                        name="twitter_site"
                        type="text"
                        placeholder="Type Here..."
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3 row">
                    <Form.Label className="col-md-3 align-self-center mb-0">
                      Twitter Domain
                    </Form.Label>
                    <Col md={9}>
                      <Field
                        as={Form.Control}
                        name="twitter_domain"
                        type="text"
                        placeholder="Type Here..."
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <div className="text-end">
                <Button type="submit" className="ma-btn-primary">
                  {edit.id ? "Save Changes" : "Save"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default AddData;
