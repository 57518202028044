import { Formik } from "formik";
import React from "react";
import { useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { BiEdit } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { selectUser, setUser } from "../../features/authSlice";
import { updatePartnerDetails, updatePlugin } from "../../Services/adminApi";
import { addCompanySchema } from "../../utils/validationSchemas";

const HomeCms = () => {
  const notify = (message) => {
    toast(message);
  };
  const dispatch = useDispatch();
  const { user } = useSelector(selectUser);

  const [selectedImage, setSelectedImage] = useState(null);
  const handlePluginData = async (values, setSubmitting) => {
    const bodyFormData = new FormData();
    bodyFormData.set("id", user.login_id || user.id);
    bodyFormData.set("update_type", "plugin_info");
    bodyFormData.set(
      "background_image",
      selectedImage ? selectedImage : values.background_image
    );
    bodyFormData.set("company_name", values.company_name);
    bodyFormData.set("company_profile", values.company_profile);
    bodyFormData.set("company_address", values.company_address);
    bodyFormData.set("company_phone_no", values.company_phone_no);
    bodyFormData.set("website_URL", values.website_URL);
    bodyFormData.set("plugin_appearance", values.plugin_appearance);

    const res = await updatePlugin(bodyFormData);
    console.log(res);
    if (res.status) {
      notify(res.message);
      dispatch(setUser(res.data[0]));
    } else {
      notify(res.message);
    }
    setSubmitting(false);
  };
  return (
    <>
      <Row>
        <Col md={12}>
          <Card className="h-100" data-aos={"fade-up"} data-aos-delay={200}>
            <Card.Header>
              <Card.Title>-- Manage Home Section Content --</Card.Title>
            </Card.Header>
            <Card.Body className="pt-0">
              <Formik
                validationSchema={addCompanySchema}
                enableReinitialize={true}
                initialValues={{
                  background_image: user?.background_image
                    ? user?.background_image
                    : "",
                  company_name: user?.company_name,
                  company_profile: user?.company_profile,
                  company_address: user?.company_address,
                  company_phone_no: user?.company_phone_no,
                  website_URL: user?.website_URL,
                  plugin_appearance: user?.plugin_appearance,
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  handlePluginData(values, setSubmitting, resetForm);
                }}>
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}
                    className="form-horizontal">
                    <Form.Group className="mb-3 row">
                      <Form.Label className="col-md-3 align-self-center mb-0">Background Image</Form.Label>
                      <Col md={6}>
                        <div className="d-flex">
                          <img
                            src={
                              selectedImage
                                ? URL.createObjectURL(selectedImage)
                                : user?.background_image
                            }
                            width={"100px"}
                          />
                          <BiEdit
                            className="fs-4 position-relative "
                            style={{ left: "20px" }}
                          />
                          <Form.Control
                            onChange={(e) => {
                              handleChange(e);
                              setSelectedImage(e.target.files[0]);
                            }}
                            onBlur={handleBlur}
                            className="position-relative opacity-0 "
                            name="background_image"
                            type="file"
                            style={{ width: "20px" }}
                          />

                          <small className="text-danger">
                            {errors.background_image &&
                              touched.background_image &&
                              errors.background_image}
                          </small>
                        </div>
                      </Col>
                    </Form.Group>
                    <Form.Group className="mb-3 row">
                      <Form.Label className="col-md-3 align-self-center mb-0">Seo Image</Form.Label>
                      <Col md={6}>
                        <div className="d-flex">
                          <img
                            src={
                              selectedImage
                                ? URL.createObjectURL(selectedImage)
                                : user?.background_image
                            }
                            width={"100px"}
                          />
                          <BiEdit
                            className="fs-4 position-relative "
                            style={{ left: "20px" }}
                          />
                          <Form.Control
                            onChange={(e) => {
                              handleChange(e);
                              setSelectedImage(e.target.files[0]);
                            }}
                            onBlur={handleBlur}
                            className="position-relative opacity-0 "
                            name="background_image"
                            type="file"
                            style={{ width: "20px" }}
                          />

                          <small className="text-danger">
                            {errors.background_image &&
                              touched.background_image &&
                              errors.background_image}
                          </small>
                        </div>
                      </Col>
                    </Form.Group>
                    <Form.Group className="mb-3 row">
                      <Form.Label className="col-md-3 align-self-center mb-0">
                        Seo Name <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Col md={6}>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="company_name"
                          value={values.company_name}
                          type="text"
                          placeholder="Enter Business Name"
                        />
                        <small className="text-danger">
                          {errors.company_name &&
                            touched.company_name &&
                            errors.company_name}
                        </small>
                      </Col>
                    </Form.Group>
                    <Form.Group className="mb-3 row">
                      <Form.Label className="col-md-3 align-self-center mb-0">
                        Heading Description{" "}
                        <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Col md={6}>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="company_profile"
                          value={values.company_profile}
                          type="text"
                          placeholder="Enter Business Description"
                        />
                        <small className="text-danger">
                          {errors.company_profile &&
                            touched.company_profile &&
                            errors.company_profile}
                        </small>
                      </Col>
                    </Form.Group>
                    <Form.Group className="mb-3 row">
                      <Form.Label className="col-md-3 align-self-center mb-0">
                        Text Description<span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Col md={6}>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="company_address"
                          value={values.company_address}
                          type="text"
                          placeholder="Enter Business Address"
                        />
                        <small className="text-danger">
                          {errors.company_address &&
                            touched.company_address &&
                            errors.company_address}
                        </small>
                      </Col>
                    </Form.Group>
                    <Form.Group className="mb-3 row">
                      <Form.Label className="col-md-3 align-self-center mb-0">
                        Watch Video Url. <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Col md={6}>
                        <Form.Control
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="company_phone_no"
                          maxLength={10}
                          minLength={10}
                          value={values.company_phone_no}
                          type="text"
                          placeholder="Enter Phone No."
                        />
                        <small className="text-danger">
                          {errors.company_phone_no &&
                            touched.company_phone_no &&
                            errors.company_phone_no}
                        </small>
                      </Col>
                    </Form.Group>
                    <hr />
                    <Button
                      variant="primary"
                      type={"submit"}
                      className="ma-btn-primary">
                      Save Change
                    </Button>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default HomeCms;
