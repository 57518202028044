import React, { useState } from "react";
import Chart from "react-apexcharts";
import { Col, Card, Container, Form, Row } from "react-bootstrap";
import { BsEyeFill, BsFileEarmarkText } from "react-icons/bs";
import DataTable from "react-data-table-component";
import { getUpdateUrl } from "../../Services/adminApi";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/authSlice";

const Coverage = () => {
  const { user } = useSelector(selectUser);
  const [allUrls, setAllUrls] = useState([]);
  const [chartData3, setChartData3] = useState({
    series: [
      {
        name: "Net Profit",
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
      },
      {
        name: "Revenue",
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
      },
      {
        name: "Free Cash Flow",
        data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
      },
    ],
    options: {
      chart: {
        type: "bar",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "100%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
        ],
      },
      fill: {
        opacity: 1,
        colors: ["#5addfc", "#a6f5da", "#00e396"],
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands";
          },
        },
      },
    },
  });

  const linetab = [
    {
      id: 1,
      number: 3,
      text: "Valid",
      text2: "Total No. of Valid Pages",
      icon: (
        <>
          <BsEyeFill />
        </>
      ),
      delay: 100,
      class: "success",
    },
    {
      id: 2,
      number: 7,
      text: "Clicks",
      text2: "Total No. of Clicks",
      icon: (
        <>
          <BsFileEarmarkText />
        </>
      ),
      delay: 200,
      class: "warning",
    },
  ];

  const [filteredResults, setFilteredResults] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredData = allUrls.filter((item) => {
        return Object.values(item.url)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(allUrls);
    }
  };

  const columns = [
    {
      name: "S.No.",
      selector: (row) => row.id,
    },
    {
      name: "Top Pages",
      selector: (row) => row.url,
    },
    {
      name: "Clicks",
      selector: (row) => row.clicks,
    },
    {
      name: "Last Visit",
      selector: (row) => row.last_visit,
    },
  ];

  const fetchData = async () => {
    const res = await getUpdateUrl(user.login_id || user.id);
    if (res.status) {
      setAllUrls(res.data);
    } else {
      setAllUrls([]);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Container fluid className="mt-n5">
        <Row>
          <Col md={4}>
            <Row className="g-4">
              {linetab.map((tabcount, ibx) => (
                <Col key={ibx} md={6}>
                  <Card
                    className="h-100"
                    data-aos={"fade-up"}
                    data-aos-delay={tabcount.delay}
                  >
                    <Card.Body>
                      <div className="d-flex align-items-center justify-content-between">
                        <div
                          className={`p-2 ani-icon d-inline-flex fs-4 text-primary rounded-circle bg-soft-${tabcount.class}`}
                        >
                          {tabcount.icon}
                        </div>
                        <h1
                          className={`counter my-3 fw-bold text-truncate text-${tabcount.class}`}
                        >
                          {tabcount.number}
                        </h1>
                      </div>

                      <div className="mt-1">
                        <h5 className="mb-2">{tabcount.text}</h5>
                        <p className="mb-1">{tabcount.text2}</p>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
          <Col md={8}>
            <Card data-aos={"fade-left"} className="mt-md-0 mt-4">
              <Card.Header>
                <div className="header-title w-100 align-items-center flex-wrap-reverse gap-md-0 gap-3 d-flex justify-content-between"></div>
              </Card.Header>
              <Card.Body className="py-2">
                <Chart
                  options={chartData3.options}
                  series={chartData3.series}
                  type="bar"
                  height={170}
                />
              </Card.Body>
            </Card>
          </Col>
          <Col md={12}>
            <Card data-aos={"fade-up"} data-aos-delay={200}>
              <Card.Header>
                <div className="header-title w-100 align-items-center flex-wrap-reverse gap-md-0 gap-3 d-flex justify-content-between">
                  <h4 className="card-title">Web Coverage</h4>
                  <Form.Control
                    type="search"
                    className="w-auto ms-auto me-3 search-field"
                    id="search-data"
                    onChange={(e) => searchItems(e.target.value)}
                    placeholder="Search"
                  />
                </div>
              </Card.Header>
              <Card.Body className="p-0">
                <DataTable
                  columns={columns}
                  data={searchInput.length > 1 ? filteredResults : allUrls}
                  pagination
                  searchbar
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Coverage;
