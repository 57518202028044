import React from "react";

const PostsData = ({ data }) => {
  return (
    <>
      <div className="d-flex p-3">
        <p
          className="fw-bold ms-2"
          style={{ fontSize: "14px", color: "#808080e8" }}
        >
          Description :
        </p>

        <small
          className="text-justify"
          style={{ marginLeft: "50px", width: "800px" }}
          dangerouslySetInnerHTML={{ __html: data.description }}
        />
      </div>
    </>
  );
};

export default PostsData;
