import React, { useState } from "react";
import { useEffect } from "react";
import { Col, Card, Container, Row, Table, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/authSlice";
import { getScriptData } from "../../Services/adminApi";
import moment from "moment";
import { toast } from "react-toastify";

const PluginSetup = () => {
  const notify = (messages) => {
    toast.success(messages);
  };
  const [scriptData, setScriptData] = useState("");

  const { user } = useSelector(selectUser);
  const download = async () => {
    const webUrl = "https://dunkez.com/api/api";
    const res = `
    <html>
    <head>
        <style>
            \.singin > form > div > input {
                background: #edf2f6 none repeat scroll 0 0;
                border: medium none;
                font-size: 13px;
                margin-bottom: 5px;
                padding: 10px 20px;
                width: 100%;
            }
        </style>
        <script src="https://code.jquery.com/jquery-3.6.0.js" integrity="sha256-H+K7U5CnXl1h5ywQfKtSj8PCmoN9aaq30gDh27Xc0jk=" crossorigin="anonymous"><\/script>
    <\/head>
    <body>
        <script>
            $(document).ready(function () {
            
                let text = window.location.href;
                let lastFour = text.substr(text.length - 4);
                var num = "";
                let id = "";
                if(lastFour!="html"){
                    var numFormat = text.split(".html")[1];
                    var numFormatRes = numFormat.substring(0, numFormat.lastIndexOf("/") + 1);
                    num = numFormatRes.replace("?", "");
                    num = num.replace("/", "");
                    num = btoa(num);
                }
              
                var siteurl = '${webUrl}/update.html';
                $.ajax({
                    type: "post",
                    headers: {"Authorization": "Bearer ${localStorage.getItem(
                      "token"
                    )}"},
                    url: '${webUrl}/updatefiledata',
                    data: {
                        // created_by: '.$webUrl.',
                        ekey      : '${user.ekey}',
                        ekeypass  : '${user.ekeypass}',
                        postId    : num,
                        siteurl   : "https://${user.website_URL}/update.html"
                    },
                    dataType: "json",
                    success: function (response) {
                        if (response.status) {
                            $("head").prepend(response.head);
                            $("body").append(response.body);
                        }
                    },
                    error: function (error) {
                        console.log(error);
                    },
                });
                
            });

           $(document).on("click", "#dealSave", function(){
                $(".validation").remove();
                let name      = $("#dealName").val();
                let phone     = $("#dealPhone").val();
                let mesg     = $("#messageMessage").val();
                let unknownId = $("#unknownId").val();
                let flag      = true;

                if(!name){
                    $("#dealName").parent().append('<small class="text-danger validation">Please enter name.</small>');
                    flag = false;
                }
                if(!phone){
                    $("#dealPhone").parent().append('<small class="text-danger validation">Please enter phone number.</small>');
                    flag = false;
                }
                if(unknownId==""){
                    $("#dealPhone").parent().append('<small class="text-danger validation">OOPS! Something wrong, Please try after some time.</small>');
                    flag = false;
                }
                if(false){
                    $.ajax({
                        type: "post",
                        url: "${webUrl}/post-enquiry",
                        data: {
                            name      : name,
                            phone     : phone,
                            message   : mesg,
                            unknownId : unknownId
                        },
                        dataType: "json",
                        success: function (response) {
                            if(response.status){
                                $("#dealName").val("");
                                $("#dealPhone").val("");
                                $("#messageMessage").val("");
                                $("#messageMessage").parent().append('<small class="text-success validation">'+response.msg+'</small>');
                            }else{
                                if(response.msg.name){
                                    $("#dealName").parent().append('<small class="text-danger validation">'+response.msg.name+'</small>');
                                }
                                if(response.msg.phone){
                                    $("#dealPhone").parent().append('<small class="text-danger validation">'+response.msg.phone+'</small>');
                                }
                                if(response.msg.unknownId){
                                    $("#messageMessage").parent().append('<small class="text-danger validation">OOPS! Somethings wrong please try again.</small>');
                                }
                                
                            }
                        },
                        error: function (error) {
                        },
                    });
                }
            });
            
            $(document).on("click", "#leadSaveModal", function(){
                $(".validation").remove();
                let name      = $("#plugin__enquery-name").val();
                let phone     = $("#plugin__enquery-phone").val();
                let message     = $("#plugin__enquery-message").val();
                let unknownId = $("#unknownId").val();
                let flag      = true;

                if(!name){
                    $("#plugin__enquery-name").parent().append('<small class="text-danger validation">Please enter name.</small>');
                    flag = false;
                }
                if(!phone){
                    $("#plugin__enquery-phone").parent().append('<small class="text-danger validation">Please enter phone number.</small>');
                    flag = false;
                }
                if(!message){
                    $("#plugin__enquery-message").parent().append('<small class="text-danger validation">Please enter message.</small>');
                    flag = false;
                }
                if(unknownId==""){
                    $("#plugin__enquery-phone").parent().append('<small class="text-danger  validation">OOPS! Something wrong, Please try after some time.</small>');
                    flag = false;
                }
                if(false){
                    $.ajax({
                        type: "post",
                        url: "${webUrl}/post-enquiry",
                        data: {
                            name      : name,
                            phone     : phone,
                            message   : message,
                            unknownId : unknownId
                        },
                        dataType: "json",
                        success: function (response) {
                            if(response.status){
                                $("#plugin__enquery-name").val("");
                                $("#plugin__enquery-phone").val("");
                                $("#plugin__enquery-message").val("");
                                $("#plugin__enquery-message").parent().append('<small class="text-success validation" id="modal-succ">'+response.msg+'</small>');
                                
                                setTimeout(myFunction , 3000);

                                function myFunction() {
                                  $("#modal-succ").remove();
                                }

                            }else{
                                if(response.msg.name){
                                    $("#plugin__enquery-name").parent().append('<small class="text-danger validation">'+response.msg.name+'</small>');
                                }
                                if(response.msg.phone){
                                    $("#plugin__enquery-phone").parent().append('<small class="text-danger validation">'+response.msg.phone+'</small>');
                                }
                                if(response.msg.unknownId){
                                    $("#plugin__enquery-message").parent().append('<small class="text-danger validation">OOPS! Somethings wrong please try again.</small>');
                                }
                                
                            }
                        },
                        error: function (error) {
                        },
                    });
                }
            });

            $(document).on("keyup", "#dealName", function(){
                $(this).parent().find(".validation").remove();
            })
            $(document).on("keyup", "#plugin__enquery-name", function(){
                $(this).parent().find(".validation").remove();
            })
            
            
            
            $(document).on("keyup", "#dealPhone", function(){
                // return /[0-9,]/i.test(event.key)
                $(this).parent().find(".validation").remove();
            })
            
            $(document).on("keydown", "#dealPhone", function(e){
                var key = e.charCode || e.keyCode || 0;
                // allow backspace, tab, delete, enter, arrows, numbers and keypad numbers ONLY
                // home, end, period, and numpad decimal
                return (
                    key == 8 || 
                    key == 9 ||
                    key == 13 ||
                    key == 46 ||
                    key == 110 ||
                    key == 190 ||
                    (key >= 35 && key <= 40) ||
                    (key >= 48 && key <= 57) ||
                    (key >= 96 && key <= 105));
                })
                
                 $(document).on("keyup", "#plugin__enquery-phone", function(){
                // return /[0-9,]/i.test(event.key)
                $(this).parent().find(".validation").remove();
            })
            
            $(document).on("keydown", "#plugin__enquery-phone", function(e){
                var key = e.charCode || e.keyCode || 0;
                // allow backspace, tab, delete, enter, arrows, numbers and keypad numbers ONLY
                // home, end, period, and numpad decimal
                return (
                    key == 8 || 
                    key == 9 ||
                    key == 13 ||
                    key == 46 ||
                    key == 110 ||
                    key == 190 ||
                    (key >= 35 && key <= 40) ||
                    (key >= 48 && key <= 57) ||
                    (key >= 96 && key <= 105));
            })

           
        <\/script>
      <\/body>
        <\/html>
    `;
    const filename = "update.html";
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(res)
    );
    element.setAttribute("download", filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
  };

  const fetchScriptData = async () => {
    const aData = {
      id: user.login_id || user.id,
    };
    const res = await getScriptData(aData);
    if (res.status) {
      setScriptData(res.data);
    } else {
      setScriptData("");
    }
  };

  useEffect(() => {
    fetchScriptData();
  }, []);

  return (
    <>
      <Container fluid className="mt-n5">
        <Row>
          <Col md={12}>
            <Card data-aos={"fade-up"}>
              <Card.Body className="py-2">
                <Row>
                  <Col md={12}>
                    <Table responsive striped bordered hover>
                      <tbody>
                        <tr className="border-dark">
                          <th className="border-0">Authentication key</th>
                          <td className="border-0">
                            <code>{user?.ekey}</code>
                          </td>
                        </tr>
                        <tr className="border-dark">
                          <th className="border-0">Plugin Expire</th>
                          <td className="border-0">
                            <code>
                              {moment(user.expire_date).format("DD-MM-YYYY")}
                            </code>
                          </td>
                        </tr>
                        <tr>
                          <th className="fw-bold text-black">HTML File</th>
                          <td>
                            <code>Upload an HTML File to your website</code>
                          </td>
                        </tr>
                        <tr>
                          <th>1. Download the file:</th>
                          <td>
                            <code>
                              <Button
                                className="ma-btn-primary"
                                onClick={() => download()}
                              >
                                Download
                              </Button>
                            </code>
                          </td>
                        </tr>

                        <tr>
                          <th>2. Upload to:</th>
                          <td>
                            <code>{user.website_URL}</code>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <div className="">
                      <h5 className="fw-bold">Just Copy...</h5>
                      <p>
                        <code>
                          Here's your unique Code snippet. Click this code to
                          copy it right to your clipboard.
                        </code>
                      </p>
                      <Card>
                        <div className="text-end">
                          <Button
                            style={{ width: "150px" }}
                            onClick={() => {
                              if (
                                navigator.clipboard &&
                                window.isSecureContext
                              ) {
                                return navigator.clipboard
                                  .writeText(scriptData)
                                  .then(() => {
                                    notify("Code Copied Successfuly!");
                                  });
                              } else {
                                let textArea =
                                  document.createElement("textarea");
                                textArea.value = scriptData;
                                textArea.style.position = "fixed";
                                textArea.style.left = "-999999px";
                                textArea.style.top = "-999999px";
                                document.body.appendChild(textArea);
                                textArea.focus();
                                textArea.select();
                                return new Promise((res, rej) => {
                                  document.execCommand("copy") ? res() : rej();
                                  textArea.remove();
                                  notify("Code Copied Successfuly!");
                                });
                              }
                            }}
                          >
                            Copy Code
                          </Button>
                        </div>
                        <pre
                          className="text-black p-3"
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {scriptData}
                        </pre>

                        <hr />
                        <div className="mt-0 m-3">
                          <h5 className="fw-bold">... & paste.</h5>
                          <p>
                            <code>
                              Paste the above code right above the closing of
                              the HTML in your website on every page you want
                              ePlugin to work.
                            </code>
                          </p>
                        </div>
                        <div className="p-3">
                          <img
                            src="https://dunkez.com/api/uploads/images/default/setup.png"
                            className="mb-4 plugin-image"
                          />
                          <h5 className="mb-3">
                            It should be that easy, but if something's not
                            working or you need more help, you can contact us
                          </h5>
                          <h4 className="mb-3">
                            In Case of WordPress Websites:
                          </h4>
                          <p>Step 1: Go to the admin panel</p>
                          <p>
                            Step 2: In the left panel, select plugins section
                          </p>
                          <p>
                            Step 3: Install and Activate the following plugins:
                          </p>
                          <p>
                            <dt>
                              <li>Custom CSS & JavaScript</li>
                            </dt>
                          </p>
                          <p>Step 4: Go to Custom CSS & JS</p>
                          <p>Step 5: Select "Add Custom HTML"</p>
                          <p>
                            Step 6: In the title section, name it however you
                            want
                          </p>
                          <p>
                            Step 7: In the code section, copy and paste the code
                            above
                          </p>
                          <p>
                            Step 8: In the Options section, select the
                            following:
                          </p>
                          <p>
                            <dt>
                              <li>Where on page</li>
                              <ul>
                                <li>Footer</li>
                              </ul>
                            </dt>
                          </p>
                          <p>
                            <dt>
                              <li>Where in site</li>
                              <ul>
                                <li>In Frontend</li>
                              </ul>
                            </dt>
                          </p>
                          <p>
                            Step 9: In the Publish section, click on publish
                          </p>
                          <p>
                            ... And, that's it! That is all you have to do and
                            the plugin will be updated on your website!
                          </p>
                        </div>
                      </Card>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PluginSetup;
