import React, { useState } from "react";
import {
  BsArrowLeft,
  BsChevronRight,
  BsCurrencyDollar,
  BsEnvelope,
  BsFillRecord2Fill,
  BsGear,
  BsTools,
  BsHouseDoor,
  BsMegaphone,
  BsSpeedometer2,
  BsStopwatch,
  BsFilePost,
  BsChatText,
  BsBookHalf,
} from "react-icons/bs";
import { Collapse, Nav, Navbar } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import useMediaQuery from "./Hooks/useMediaQuery";

const Sidebar = ({ sidebaropen, toggle }) => {
  const isDesktop = useMediaQuery("(min-width: 768px)");

  const sidemap = [
    {
      id: 1,
      sidemap1: [
        {
          id: 1,
          title: "Dashboard",
          url: "/dashboard",
          icon: (
            <>
              <BsSpeedometer2 className="fs-5" />
            </>
          ),
        },
      ],
    },
    {
      id: 2,
      sidemap1: [
        {
          id: 1,
          title: "Inbox",
          url: "/Inbox",
          icon: (
            <>
              <BsEnvelope className="fs-5" />
            </>
          ),
        },
      ],
    },
    {
      id: 3,
      sidemap2: [
        {
          id: 1,
          title: "Account Settings",
          icon: (
            <>
              <BsGear className="fs-5" />
            </>
          ),
          side2: [
            {
              id: 1,
              title: "Settings",
              url: "/Settings",
              mini: "S",
            },
            // {
            //   id: 2,
            //   title: 'CMS Settings',
            //   url: '/CmsSetting',
            //   mini: 'C',
            // },
          ],
        },
      ],
    },
    {
      id: 4,
      sidemap2: [
        {
          id: 1,
          title: "Setup & Installation",
          url: "#",
          icon: (
            <>
              <BsTools className="fs-5" />
            </>
          ),
          side2: [
            {
              id: 1,
              title: "Plugin Setup",
              url: "/PluginSetup",
              mini: "P",
            },
          ],
        },
      ],
    },
    {
      id: 5,
      sidemap2: [
        {
          id: 1,
          title: "Post & Updates",
          url: "#",
          icon: (
            <>
              <BsStopwatch className="fs-5" />
            </>
          ),
          side2: [
            {
              id: 1,
              title: "Add Updates",
              url: "/AddUpdates",
              mini: "U",
            },
          ],
        },
      ],
    },
    {
      id: 5,
      sidemap2: [
        {
          id: 1,
          title: "Blogs",
          url: "#",
          icon: (
            <>
              <BsChatText className="fs-5" />
            </>
          ),
          side2: [
            {
              id: 1,
              title: "Add Blog",
              url: "/AddBlogs",
              mini: "U",
            },
          ],
        },
      ],
    },
    {
      id: 6,
      sidemap2: [
        {
          id: 1,
          title: "Manage Keywords",
          url: "#",
          icon: (
            <>
              <BsMegaphone className="fs-5" />
            </>
          ),
          side2: [
            {
              id: 1,
              title: "Add Keywords",
              url: "/AddKeywords",
              mini: "K",
            },
          ],
        },
      ],
    },
    {
      id: 7,
      sidemap2: [
        {
          id: 1,
          title: "eSearch Console",
          url: "#",
          icon: (
            <>
              <BsCurrencyDollar className="fs-5" />
            </>
          ),
          side2: [
            {
              id: 1,
              title: "Owner Verification",
              url: "/OwnerVerification",
              mini: "O",
            },
            {
              id: 1,
              title: "Coverage",
              url: "/Coverage",
              mini: "C",
            },
          ],
        },
      ],
    },
    {
      id: 8,
      sidemap1: [
        {
          id: 1,
          title: "Knowledge Base",
          url: "/KnowledgeBase",
          icon: (
            <>
              <BsBookHalf className="fs-5" />
            </>
          ),
        },
      ],
    },
  ];
  const [open, setOpen] = useState({});
  const handleClick = (id) => {
    setOpen((prevState) => ({ [id]: !prevState[id] }));
  };

  return (
    <>
      <aside
        className={`sidebar sidebar-default navs-rounded-all 
         ${sidebaropen ? null : "sidebar-mini"}
        `}
      >
        <div className="sidebar-header border-0 d-flex align-items-center justify-content-start">
          <Link to="/dashboard" className="navbar-brand p-md-2">
            <img
              src="/logo.png"
              width={170}
              className="img-fluid default-img"
            />
            <img src="/mini-logo.png" width={50} className="mini-img" />
          </Link>
          <Navbar.Toggle onClick={toggle} className="sidebar-toggle bg-white">
            <i className="icon">
              <BsArrowLeft className="text-muted" />
            </i>
          </Navbar.Toggle>
        </div>
        <div className="sidebar-body pt-3 data-scrollbar">
          <div className="scroll-content">
            <div className="sidebar-list">
              <ul
                className="navbar-nav iq-main-menu my-sidebar"
                id="sidebar-menu"
              >
                {sidemap.map((index, id) => (
                  <div key={id}>
                    {index.sidemap1?.length > 0 &&
                      index.sidemap1?.map((side, ida) => (
                        <li key={ida} className="nav-item">
                          <Nav.Link as={NavLink} to={side.url}>
                            <i className="icon">{side.icon}</i>
                            <span
                              className="item-name"
                              onClick={isDesktop ? "" : toggle}
                            >
                              {side.title}
                            </span>
                          </Nav.Link>
                        </li>
                      ))}
                    {index.sidemap2?.length > 0 &&
                      index.sidemap2?.map((side2, idb) => (
                        <li key={idb} className="nav-item">
                          <Nav.Link onClick={() => handleClick(side2.title)}>
                            <i className="icon">{side2.icon}</i>
                            <span className="item-name">{side2.title}</span>
                            <i className="right-icon">
                              <BsChevronRight />
                            </i>
                          </Nav.Link>
                          <Collapse in={open[side2.title]}>
                            <ul className="sub-nav">
                              {side2.side2?.map((collapse, idc) => (
                                <li key={idc} className="nav-item">
                                  <Nav.Link
                                    className="nav-link"
                                    as={NavLink}
                                    to={collapse.url}
                                  >
                                    <i className="icon">
                                      <BsFillRecord2Fill />
                                    </i>
                                    <i className="sidenav-mini-icon">
                                      {collapse.mini}
                                    </i>
                                    <span
                                      onClick={isDesktop ? "" : toggle}
                                      className="item-name"
                                    >
                                      {collapse.title}
                                    </span>
                                  </Nav.Link>
                                </li>
                              ))}
                            </ul>
                          </Collapse>
                        </li>
                      ))}
                    <li>
                      <hr className="hr-horizontal" />
                    </li>
                  </div>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="sidebar-footer" />
      </aside>
    </>
  );
};

export default Sidebar;
