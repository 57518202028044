import moment from "moment";
import React from "react";
import { BsSuitHeartFill } from "react-icons/bs";

const Footer = () => {
  return (
    <>
      <footer
        className="text-center footer mt-4 position-fixed w-100"
        style={{ left: "60px", bottom: "0" }}
      >
        <div className="footer-body d-block">
          © {moment().format("YYYY")} UnBlem, Made with{" "}
          <span className="text-gray">
            {" "}
            <BsSuitHeartFill /> by
          </span>
          <a
            href="https://sartiaglobal.com/"
            target="blank"
            className="text-danger"
          >
            {" "}
            SarTia Global
          </a>
          .
        </div>
      </footer>
    </>
  );
};

export default Footer;
