import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const NoPage = () => {
  return (
    <>
      <div className="wrapper">
        <div className="gradient" data-aos={"fade-up"}>
          <Container>
            <img src="http://localhost/admin/assets/images/error/404.png" className="img-fluid mb-4 w-50" alt="" />
            <h2 className="mb-0 mt-4 text-white">Oops! This Page is Not Found.</h2>
            <p className="mt-2 text-white">The requested page dose not exist.</p>
            <Link className="btn ma-btn-primary text-light d-inline-flex align-items-center" to="/">Back to Home</Link>
          </Container>
          <div className="box" data-aos={"fade-up"} data-aos-delay={100} style={{zIndex: '-1'}}>
            <div className="c xl-circle" data-aos={"fade-up"} data-aos-delay={200}>
              <div className="c lg-circle" data-aos={"fade-up"} data-aos-delay={300}>
                <div className="c md-circle" data-aos={"fade-up"} data-aos-delay={400}>
                  <div className="c sm-circle" data-aos={"fade-up"} data-aos-delay={500}>
                    <div className="c xs-circle" data-aos={"fade-up"} data-aos-delay={600}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NoPage