import React from "react";
import { Table } from "react-bootstrap";

const InquiriesExpandedData = ({ data }) => {
  return (
    <>
      <div className="d-flex">
        <Table size="sm" style={{ fontSize: "14px" }} bordered hover>
          <tbody>
            <tr>
              <th className="ps-3">Inquiry </th>
              <td>{data.message ? data.message : "-"}</td>
            </tr>
            <tr>
              <th className="ps-3">Created At </th>
              <td>{data.created_at}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default InquiriesExpandedData;
