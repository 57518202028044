import moment from "moment/moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { Col, Card, Container, Row, Button, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { BiEdit } from "react-icons/bi";
import { BsPlusCircle, BsTrash } from "react-icons/bs";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import AddData from "../../components/Forms/AddData";
import { selectUser } from "../../features/authSlice";
import {
  deleteKeywords,
  deleteMultipleKeywords,
  getKeywordsData,
} from "../../Services/adminApi";
import LoadingSpinner from "../../components/LoadingSpinner";
import KeywordsTable from "../../components/ExpandableData/KeywordsTable";
import { AiFillMinusCircle } from "react-icons/ai";
import { useCallback } from "react";

const AddUpdates = () => {
  const notify = (message) => {
    toast(message);
  };
  const [pending, setPending] = useState(true);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { user } = useSelector(selectUser);
  const [showTable, setshowTable] = useState(false);
  const handleCloseTable = () => setshowTable(false);
  const handleShowTable = () => setshowTable(true);
  const [keywordsData, setKeywordsData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isEdit, setIsEdit] = useState({
    id: "",
    keyword_url: "",
    og_url: "",
    title_tag: "",
    description_tag: "",
    keywords_tag: "",
    og_title: "",
    twitter_title: "",
    og_image: null,
    twitter_image: null,
    og_description: "",
    twitter_description: "",
  });

  const [filteredResults, setFilteredResults] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredData = keywordsData.filter((item) => {
        return (
          Object.values(item.keywords_tag)
            .join("")
            .toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          Object.values(item.keyword_url)
            .join("")
            .toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          Object.values(item.description_tag)
            .join("")
            .toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          Object.values(item.created_at)
            .join("")
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        );
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(keywordsData);
    }
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      maxWidth: "100px",
    },
    {
      name: "Url",
      selector: (row) => row.keyword_url,
      maxWidth: "200px",
    },
    {
      name: "Keyword Tag",
      selector: (row) => row.keywords_tag,
      maxWidth: "200px",
    },
    {
      name: "Description Tag",
      selector: (row) => row.description_tag,
      maxWidth: "200px",
    },
    {
      name: "Created Date",
      selector: (row) => moment(row.created_at).format("DD-MM-YYYY"),
      maxWidth: "200px",
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <Button
            className="p-1 me-1 bg-success border-white py-0"
            onClick={() => {
              setIsEdit({
                id: row.id,
                keyword_url: row.keyword_url,
                og_url: row.og_url,
                title_tag: row.title_tag,
                description_tag: row.description_tag,
                keywords_tag: row.keywords_tag,
                og_title: row.og_title,
                twitter_title: row.twitter_title,
                og_image: row.og_image,
                twitter_image: row.twitter_image,
                og_description: row.og_description,
                twitter_description: row.twitter_description,
                region: row.region,
                copyright: row.copyright,
                classification: row.classification,
                og_type: row.og_type,
                twitter_site: row.twitter_site,
                twitter_domain: row.twitter_domain,
              });
              handleShowTable();
            }}
          >
            <BiEdit className="fs-6" />
          </Button>
          <Button
            onClick={() => handleDelete(row.id)}
            className="p-1 bg-danger border-white py-0"
          >
            <BsTrash className="fs-6" />
          </Button>
        </>
      ),
    },
  ];

  const handleMultipleDelete = async () => {
    if (window.confirm(`Are you sure you want to delete?`)) {
      const aData = {
        ids: selectedRows.map((item) => item.id),
      };
      const res = await deleteMultipleKeywords(aData);
      if (res.status) {
        notify(res.message);
        setRefresh(!refresh);
        setToggleCleared(!toggleCleared);
        setSelectedRows([]);
      } else {
        notify(res.message);
      }
    }
  };
  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);
  const handleDelete = async (id) => {
    const dData = {
      id: id,
      created_by: user.login_id || user.id,
    };
    const res = await deleteKeywords(dData);
    if (res.status) {
      notify(res.message);
      setRefresh(!refresh);
    } else {
      notify(res.message);
    }
  };

  const fetchData = async () => {
    const kData = {
      id: user.login_id || user.id,
    };
    const res = await getKeywordsData(kData);
    if (res.status) {
      setKeywordsData(res.data);
      setPending(false);
    } else {
      setKeywordsData([]);
    }
  };
  useEffect(() => {
    fetchData();
  }, [showTable, refresh, toggleCleared]);

  return (
    <>
      <Container fluid className="mt-n5">
        <Row>
          <Col md={12}>
            <Card data-aos={"fade-up"}>
              <Card.Header>
                <div className="header-title w-100 align-items-center flex-wrap-reverse gap-md-0 gap-3 d-flex justify-content-between">
                  <h4 className="card-title">Keywords</h4>
                  <Form.Control
                    type="search"
                    className="w-auto ms-auto me-3 search-field"
                    id="search-data"
                    onChange={(e) => searchItems(e.target.value)}
                    placeholder="Search"
                  />
                  <Button
                    onClick={() => {
                      setIsEdit({
                        id: "",
                        keyword_url: "",
                        og_url: "",
                        title_tag: "",
                        description_tag: "",
                        keywords_tag: "",
                        og_title: "",
                        twitter_title: "",
                        og_image: "",
                        twitter_image: "",
                        og_description: "",
                        twitter_description: "",
                        region: "",
                        copyright: "",
                        classification: "",
                        og_type: "",
                        twitter_site: "",
                        twitter_domain: "",
                      });
                      handleShowTable();
                    }}
                    className="ma-btn-primary flex-grow-1 flex-md-grow-0 btn-shadow"
                  >
                    <BsPlusCircle /> Add New
                  </Button>
                </div>
              </Card.Header>

              <Card.Body className="p-0">
                <DataTable
                  columns={columns}
                  data={searchInput.length > 1 ? filteredResults : keywordsData}
                  selectableRows
                  progressPending={pending}
                  progressComponent={<LoadingSpinner />}
                  clearSelectedRows={toggleCleared}
                  onSelectedRowsChange={handleRowSelected}
                  expandableRows
                  expandableRowsComponent={KeywordsTable}
                  pagination
                  searchbar
                />
                {selectedRows?.length > 1 && (
                  <div className="d-flex px-3 mb-3 align-items-center justify-content-between">
                    <Button
                      onClick={handleMultipleDelete}
                      size={"sm"}
                      className="ma-btn-primary text-end btn-shadow"
                    >
                      <AiFillMinusCircle className="mb-1" /> Delete Selected{" "}
                    </Button>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <AddData close={handleCloseTable} edit={isEdit} open={showTable} />
    </>
  );
};

export default AddUpdates;
