import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { BsBellSlash } from "react-icons/bs";

const Notifications = () => {
  // const [allNotifications, setAllNotifications] = useState([]);
  // const getNotificationsData = async () => {
  //   const res = await axios.get(
  //     `${process.env.REACT_APP_API_URL}/allNotifications`
  //   );
  //   if (res.status) {
  //     setAllNotifications(res.data);
  //   } else {
  //     setAllNotifications([]);
  //   }
  // };
  // useEffect(() => {
  //   getNotificationsData();
  // }, []);

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const getAllNotifications = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/allNotifications`,
      {
        params: { page: page, per_page: 2 },
      }
    );
    if (res.status) {
      const result = res.data;
      setData((prevData) => [...prevData, ...result]);
      if (result.length === 0) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getAllNotifications();
  }, [page, loading]);

  const handleClick = () => {
    setPage(page + 1);
  };
  return (
    <>
      <Container fluid className="mt-n5">
        <Row>
          <Col md={12}>
            <Card data-aos={"fade-up"}>
              <Card.Header>
                <div className="header-title w-100 align-items-center flex-wrap-reverse gap-md-0 gap-3 d-flex justify-content-between">
                  <h4 className="card-title">All Notifications</h4>
                  <Button>Mark All As Read</Button>
                </div>
              </Card.Header>

              <Card.Body className="p-0">
                <div className="main-panel bg-primary">
                  <div className="panel-body">
                    <div className="panel-left"></div>
                    <div className="panel-right">
                      {data?.length > 0 ? (
                        data.slice(0 ? data.length : 2).map((ele) => (
                          <div className="card" key={ele.id}>
                            <div className="card-log">{ele.time}</div>
                            <p>
                              <strong>{ele.name}</strong> {ele.comment}
                            </p>
                          </div>
                        ))
                      ) : (
                        <div
                          className="text-center p-4"
                          style={{ opacity: 0.1 }}
                        >
                          <BsBellSlash size={200} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Card.Body>
              {loading ? (
                <div className="text-end m-3">
                  <Button
                    onClick={handleClick}
                    style={{ width: "150px", background: "#5addfc" }}
                  >
                    Load More
                  </Button>
                </div>
              ) : (
                ""
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Notifications;
