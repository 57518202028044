import { Formik } from "formik";
import React from "react";
import { useState } from "react";
import { Button, Card, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { BiEdit } from "react-icons/bi";
import { BsPlusCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { selectUser, setUser } from "../../features/authSlice";
import { updatePartnerDetails, updatePlugin } from "../../Services/adminApi";
import { addCompanySchema } from "../../utils/validationSchemas";

const AboutCms = () => {
    const notify = (message) => {
        toast(message);
    };
    const dispatch = useDispatch();
    const { user } = useSelector(selectUser);

    const [selectedImage, setSelectedImage] = useState(null);
    const handlePluginData = async (values, setSubmitting) => {
        const bodyFormData = new FormData();
        bodyFormData.set("id", user.login_id || user.id);
        bodyFormData.set("update_type", "plugin_info");
        bodyFormData.set(
            "background_image",
            selectedImage ? selectedImage : values.background_image
        );
        bodyFormData.set("company_name", values.company_name);
        bodyFormData.set("company_profile", values.company_profile);
        bodyFormData.set("company_address", values.company_address);
        bodyFormData.set("company_phone_no", values.company_phone_no);
        bodyFormData.set("website_URL", values.website_URL);
        bodyFormData.set("plugin_appearance", values.plugin_appearance);

        const res = await updatePlugin(bodyFormData);
        console.log(res);
        if (res.status) {
            notify(res.message);
            dispatch(setUser(res.data[0]));
        } else {
            notify(res.message);
        }
        setSubmitting(false);
    };
    return (
        <>
            <Row>
                <Col md={12}>
                    <Card className="h-100" data-aos={"fade-up"} data-aos-delay={200}>
                        <Card.Header>
                            <Card.Title>-- Manage Logo & Nav Items Content --</Card.Title>
                        </Card.Header>
                        <Card.Body className="pt-0">
                            <Formik
                                validationSchema={addCompanySchema}
                                enableReinitialize={true}
                                initialValues={{
                                    background_image: user?.background_image
                                        ? user?.background_image
                                        : "",
                                    company_name: user?.company_name,
                                    company_profile: user?.company_profile,
                                    company_address: user?.company_address,
                                    company_phone_no: user?.company_phone_no,
                                    website_URL: user?.website_URL,
                                    plugin_appearance: user?.plugin_appearance,
                                }}
                                onSubmit={(values, { setSubmitting, resetForm }) => {
                                    handlePluginData(values, setSubmitting, resetForm);
                                }}>
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                }) => (
                                    <Form noValidate onSubmit={handleSubmit}
                                        className="form-horizontal">
                                        <Form.Group className="mb-3 row">
                                            <Form.Label className="col-md-3 align-self-center mb-0">Logo Image</Form.Label>
                                            <Col md={6}>
                                                <div className="d-flex">
                                                    <img
                                                        src={
                                                            selectedImage
                                                                ? URL.createObjectURL(selectedImage)
                                                                : user?.background_image
                                                        }
                                                        width={"100px"}
                                                    />
                                                    <BiEdit className="fs-4 position-relative " style={{ left: "20px" }} />
                                                    <Form.Control
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            setSelectedImage(e.target.files[0]);
                                                        }}
                                                        onBlur={handleBlur}
                                                        className="position-relative opacity-0 "
                                                        name="background_image"
                                                        type="file"
                                                        style={{ width: "20px" }}
                                                    />

                                                    <small className="text-danger">
                                                        {errors.background_image &&
                                                            touched.background_image &&
                                                            errors.background_image}
                                                    </small>
                                                </div>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group className="mb-3 row">
                                            <Form.Label className="col-md-3 align-self-center mb-0">
                                                Nav Items<span className="text-danger">*</span>{" "}
                                            </Form.Label>
                                            <Col md={6}>
                                                <div className="my-scroll">
                                                    <FloatingLabel controlId="face2" label="Nav Items" className="mb-3">
                                                        <Form.Control type="url" className="border-bottom-0" defaultValue="About Us" />
                                                        <Form.Control type="url" defaultValue="https://dunkez.com/about" />
                                                    </FloatingLabel>
                                                    <FloatingLabel controlId="face2" label="Nav Items" className="mb-3">
                                                        <Form.Control type="url" className="border-bottom-0" defaultValue="Contact Us" />
                                                        <Form.Control type="url" defaultValue="https://dunkez.com/contact" />
                                                    </FloatingLabel>
                                                    <FloatingLabel controlId="face2" label="Nav Items" className="mb-3">
                                                        <Form.Control type="url" className="border-bottom-0" defaultValue="Privacy Policy" />
                                                        <Form.Control type="url" defaultValue="https://dunkez.com/privacypolicy" />
                                                    </FloatingLabel>
                                                    <FloatingLabel controlId="face2" label="Nav Items" className="mb-3">
                                                        <Form.Control type="url" className="border-bottom-0" defaultValue="Terms & Condition" />
                                                        <Form.Control type="url" defaultValue="https://dunkez.com/termscondition" />
                                                    </FloatingLabel>
                                                    <FloatingLabel controlId="face2" label="Nav Items" className="mb-3">
                                                        <Form.Control type="url" className="border-bottom-0" defaultValue="Blog" />
                                                        <Form.Control type="url" defaultValue="https://dunkez.com/blog" />
                                                    </FloatingLabel>
                                                </div>
                                            </Col>
                                        </Form.Group>
                                        <hr />
                                        <Button variant="primary" type={"submit"} className="ma-btn-primary">
                                            Save Change
                                        </Button>
                                    </Form>
                                )}
                            </Formik>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default AboutCms;
