import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { BsArrowLeft } from "react-icons/bs";
import { Link } from "react-router-dom";

const SentEmail = () => {
  return (
    <>
      <Container>
        <Row className="justify-content-center align-items-center min-vh-100">
          <Col md={11}>
            <Row className="gx-0">
              <Col md={5} className="mx-auto">
                <Card
                  className="h-100 text-center px-md-3 rounded-0 flex-row-reverse align-items-center rounded-end"
                  data-aos={"fade-up"}
                >
                  <Card.Body>
                    <img
                      src="https://i.ibb.co/Qfh1f4C/sentemail.png"
                      className="img-fluid"
                    />
                    <h3 className="my-4">Email sent</h3>
                    <small>
                      An email has been sent to <b>Dummy@gmail.com.</b> Follow
                      the instructions in the email to reset your password.
                    </small>
                    <p className="mt-3 text-center">
                      <Link to="/" className="text-underline">
                        <BsArrowLeft className="fs-4" /> Back to login
                      </Link>
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SentEmail;
