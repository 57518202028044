import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { BsEnvelopeOpen } from "react-icons/bs";

const Messages = () => {
  const [data, setData] = useState([]);
  // const getMessagesData = async () => {
  //   const res = await axios.get(`${process.env.REACT_APP_API_URL}/allMessages`);
  //   if (res.status) {
  //     setAllMessages(res.data);
  //   } else {
  //     setAllMessages([]);
  //   }
  // };
  // useEffect(() => {
  //   getMessagesData();
  // }, []);

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const getAllMessages = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/allMessages`,
      {
        params: { page: page, per_page: 3 },
      }
    );
    if (res.status) {
      const result = res.data;
      setData((prevData) => [...prevData, ...result]);
      if (result.length === 0) {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    getAllMessages();
  }, [page, loading]);

  const handleClick = () => {
    setPage(page + 1);
  };

  return (
    <>
      <Container fluid className="mt-n5">
        <Row>
          <Col md={12}>
            <Card data-aos={"fade-up"}>
              <Card.Header>
                <div className="header-title w-100 align-items-center flex-wrap-reverse gap-md-0 gap-3 d-flex justify-content-between">
                  <h4 className="card-title">All Messages</h4>
                </div>
              </Card.Header>

              <Card.Body className="p-0 my-4">
                {data?.length > 0 ? (
                  data.slice(0 ? data.length : 2).map((ele) => (
                    <div className="messages">
                      <div className="messages__item">
                        <div className="name fw-bold">{ele.name}</div>
                        <div className="content pt-2">{ele.comment}</div>
                        <div className="date">{ele.time}</div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center p-4" style={{ opacity: 0.1 }}>
                    <BsEnvelopeOpen size={200} />
                  </div>
                )}
              </Card.Body>
              {loading ? (
                <div className="text-end m-3">
                  <Button
                    onClick={handleClick}
                    style={{ width: "150px", background: "#5addfc" }}
                  >
                    Load More
                  </Button>
                </div>
              ) : (
                ""
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Messages;
