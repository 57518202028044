import { Formik } from "formik";
import React from "react";
import { useState } from "react";
import { Button, Card, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { BiEdit } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { selectUser, setUser } from "../../features/authSlice";
import { updatePartnerDetails, updatePlugin } from "../../Services/adminApi";
import { addCompanySchema } from "../../utils/validationSchemas";

const AboutCms = () => {
  const notify = (message) => {
    toast(message);
  };
  const dispatch = useDispatch();
  const { user } = useSelector(selectUser);

  const [selectedImage, setSelectedImage] = useState(null);
  const handlePluginData = async (values, setSubmitting) => {
    const bodyFormData = new FormData();
    bodyFormData.set("id", user.login_id || user.id);
    bodyFormData.set("update_type", "plugin_info");
    bodyFormData.set(
      "background_image",
      selectedImage ? selectedImage : values.background_image
    );
    bodyFormData.set("company_name", values.company_name);
    bodyFormData.set("company_profile", values.company_profile);
    bodyFormData.set("company_address", values.company_address);
    bodyFormData.set("company_phone_no", values.company_phone_no);
    bodyFormData.set("website_URL", values.website_URL);
    bodyFormData.set("plugin_appearance", values.plugin_appearance);

    const res = await updatePlugin(bodyFormData);
    console.log(res);
    if (res.status) {
      notify(res.message);
      dispatch(setUser(res.data[0]));
    } else {
      notify(res.message);
    }
    setSubmitting(false);
  };

  const counter = [
    {
      id: 1,
      number: 328,
      title: 'Successful projects',
      description: 'Lorem ipsum as a placeholder for the page.',
    },
    {
      id: 2,
      number: '10+',
      title: 'Years of exeperience',
      description: 'Lorem ipsum as a placeholder for the page.',
    },
    {
      id: 3,
      number: '60%',
      title: 'Cost savings',
      description: 'Lorem ipsum as a placeholder for the page.',
    },
    {
      id: 4,
      number: '1k',
      title: 'Email leads generated',
      description: 'Lorem ipsum as a placeholder for the page.',
    },
  ]
  return (
    <>
      <Row>
        <Col md={12}>
          <Card className="h-100" data-aos={"fade-up"} data-aos-delay={200}>
            <Card.Header>
              <Card.Title>-- Manage About Page & Business Analyst Content --</Card.Title>
            </Card.Header>
            <Row>
              <Col md={6}>
                <Card.Body className="pt-0">
                  <Formik
                    validationSchema={addCompanySchema}
                    enableReinitialize={true}
                    initialValues={{
                      background_image: user?.background_image
                        ? user?.background_image
                        : "",
                      company_name: user?.company_name,
                      company_profile: user?.company_profile,
                      company_address: user?.company_address,
                      company_phone_no: user?.company_phone_no,
                      website_URL: user?.website_URL,
                      plugin_appearance: user?.plugin_appearance,
                    }}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      handlePluginData(values, setSubmitting, resetForm);
                    }}>
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}
                        className="form-horizontal">
                        <Form.Group className="mb-3 row">
                          <Form.Label className="col-md-5 align-self-center mb-0">About Image</Form.Label>
                          <Col md={7}>
                            <div className="d-flex">
                              <img
                                src={
                                  selectedImage
                                    ? URL.createObjectURL(selectedImage)
                                    : user?.background_image
                                }
                                width={"100px"}
                              />
                              <BiEdit
                                className="fs-4 position-relative "
                                style={{ left: "20px" }}
                              />
                              <Form.Control
                                onChange={(e) => {
                                  handleChange(e);
                                  setSelectedImage(e.target.files[0]);
                                }}
                                onBlur={handleBlur}
                                className="position-relative opacity-0 "
                                name="background_image"
                                type="file"
                                style={{ width: "20px" }}
                              />

                              <small className="text-danger">
                                {errors.background_image &&
                                  touched.background_image &&
                                  errors.background_image}
                              </small>
                            </div>
                          </Col>
                        </Form.Group>
                        <Form.Group className="mb-3 row">
                          <Form.Label className="col-md-5 align-self-center mb-0">
                            About Name <span className="text-danger">*</span>{" "}
                          </Form.Label>
                          <Col md={7}>
                            <Form.Control
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="company_name"
                              value={values.company_name}
                              type="text"
                              placeholder="Enter Business Name"
                            />
                            <small className="text-danger">
                              {errors.company_name &&
                                touched.company_name &&
                                errors.company_name}
                            </small>
                          </Col>
                        </Form.Group>
                        <Form.Group className="mb-3 row">
                          <Form.Label className="col-md-5 align-self-center mb-0">
                            About Heading Description{" "}
                            <span className="text-danger">*</span>{" "}
                          </Form.Label>
                          <Col md={7}>
                            <Form.Control
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="company_profile"
                              value={values.company_profile}
                              type="text"
                              placeholder="Enter Business Description"
                            />
                            <small className="text-danger">
                              {errors.company_profile &&
                                touched.company_profile &&
                                errors.company_profile}
                            </small>
                          </Col>
                        </Form.Group>
                        <Form.Group className="mb-3 row">
                          <Form.Label className="col-md-5 align-self-center mb-0">
                            About Text Description<span className="text-danger">*</span>{" "}
                          </Form.Label>
                          <Col md={7}>
                            <Form.Control
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="company_address"
                              value={values.company_address}
                              type="text"
                              placeholder="Enter Business Address"
                            />
                            <small className="text-danger">
                              {errors.company_address &&
                                touched.company_address &&
                                errors.company_address}
                            </small>
                          </Col>
                        </Form.Group>
                        <Form.Group className="mb-3 row">
                          <Form.Label className="col-md-5 align-self-center mb-0">
                            About Tags<span className="text-danger"> (maximum 4)*</span>{" "}
                          </Form.Label>
                          <Col md={7}>
                            <Typeahead className='text-shadow-1' allowNew id="custom-selections-example" multiple newSelectionPrefix="Add a new tag: " options={[]} placeholder="Type here..." />
                          </Col>
                        </Form.Group>
                        <Form.Group className="mb-3 row">
                          <Form.Label className="col-md-5 align-self-center mb-0">
                            Facebook Url. <span className="text-danger">*</span>{" "}
                          </Form.Label>
                          <Col md={7}>
                            <Form.Control
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="company_phone_no"
                              maxLength={10}
                              minLength={10}
                              value={values.company_phone_no}
                              type="text"
                              placeholder="Enter Phone No."
                            />
                            <small className="text-danger">
                              {errors.company_phone_no &&
                                touched.company_phone_no &&
                                errors.company_phone_no}
                            </small>
                          </Col>
                        </Form.Group>
                        <Form.Group className="mb-3 row">
                          <Form.Label className="col-md-5 align-self-center mb-0">
                            Twitter Url. <span className="text-danger">*</span>{" "}
                          </Form.Label>
                          <Col md={7}>
                            <Form.Control
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="company_phone_no"
                              maxLength={10}
                              minLength={10}
                              value={values.company_phone_no}
                              type="text"
                              placeholder="Enter Phone No."
                            />
                            <small className="text-danger">
                              {errors.company_phone_no &&
                                touched.company_phone_no &&
                                errors.company_phone_no}
                            </small>
                          </Col>
                        </Form.Group>
                        <Form.Group className="mb-3 row">
                          <Form.Label className="col-md-5 align-self-center mb-0">
                            Instagram Url. <span className="text-danger">*</span>{" "}
                          </Form.Label>
                          <Col md={7}>
                            <Form.Control
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="company_phone_no"
                              maxLength={10}
                              minLength={10}
                              value={values.company_phone_no}
                              type="text"
                              placeholder="Enter Phone No."
                            />
                            <small className="text-danger">
                              {errors.company_phone_no &&
                                touched.company_phone_no &&
                                errors.company_phone_no}
                            </small>
                          </Col>
                        </Form.Group>
                        <Form.Group className="mb-3 row">
                          <Form.Label className="col-md-5 align-self-center mb-0">
                            Linkedin Url. <span className="text-danger">*</span>{" "}
                          </Form.Label>
                          <Col md={7}>
                            <Form.Control
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="company_phone_no"
                              maxLength={10}
                              minLength={10}
                              value={values.company_phone_no}
                              type="text"
                              placeholder="Enter Phone No."
                            />
                            <small className="text-danger">
                              {errors.company_phone_no &&
                                touched.company_phone_no &&
                                errors.company_phone_no}
                            </small>
                          </Col>
                        </Form.Group>
                        <hr />
                        <Button
                          variant="primary"
                          type={"submit"}
                          className="ma-btn-primary">
                          Save Change
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </Card.Body>
              </Col>
              <Col md={6}>
                <Card.Body className="pt-0">
                  <Col>
                    <Form.Label>Business Analyst</Form.Label>
                    <div className="my-scroll">
                      {counter.map((slide, idp) => (
                        <div key={idp}>
                          <FloatingLabel controlId="face2" label="Counter Number">
                            <Form.Control type="text" className="border-bottom-0" defaultValue={slide.number} />
                          </FloatingLabel>
                          <FloatingLabel controlId="face2" label="Counter Title">
                            <Form.Control type="text" className="border-bottom-0" defaultValue={slide.title} />
                          </FloatingLabel>
                          <FloatingLabel controlId="face2" label="Counter Description" className="mb-3">
                            <Form.Control type="text" defaultValue={slide.description} />
                          </FloatingLabel>
                        </div>
                      ))}
                    </div>
                  </Col>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AboutCms;
