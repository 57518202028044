import React, { lazy, Suspense } from "react";
import { Card, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { BsTools } from "react-icons/bs";
import _ from "lodash";

const ChangePassword = lazy(() =>
  import("../components/SettingsTabs/ChangePassword")
);
const GeneralInformation = lazy(() =>
  import("../components/SettingsTabs/GeneralInformation")
);
const SocialInfo = lazy(() => import("../components/SettingsTabs/SocialInfo"));
const PluginInfo = lazy(() => import("../components/SettingsTabs/PluginInfo"));
// import Notifications from "../components/SettingsTabs/Notifications";

const Settings = () => {
  const tabs = [
    { id: 1, name: "General" },
    { id: 2, name: "Change Password" },
    { id: 3, name: "Social Info" },
    { id: 4, name: "Plugin Info" },
    // { id: 5, name: "Notifications" },
  ];

  return (
    <>
      <Container fluid className="mt-n5 mb-3">
        <Tab.Container id="Profile-tabs" defaultActiveKey="1">
          <Row>
            <Col md={12}>
              <Card data-aos={"fade-up"}>
                <Card.Body className="py-2">
                  <div className="d-md-flex flex-wrap align-items-center justify-content-between">
                    <div className="d-flex flex-wrap gap-md-0 gap-4 align-items-center">
                      <div className="position-relative me-3 profile-logo profile-logo1">
                        <BsTools className="fs-2" />
                      </div>
                      <div className="d-flex flex-wrap align-items-center mb-3 mb-sm-0">
                        <h4 className="me-2 h4">Website</h4>
                        <span> - General Settings</span>
                      </div>
                    </div>
                    <Nav
                      variant="pills"
                      className="bg-soft-primary d-md-inline-flex d-block rounded-4 mb-0"
                    >
                      {tabs.map((tab, idx) => (
                        <Nav.Item key={idx}>
                          <Nav.Link
                            className="ma-btn-primary"
                            eventKey={tab.id}
                          >
                            {tab.name}
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={12}>
              <Tab.Content>
                <Tab.Pane eventKey={"1"}>
                  <Suspense fallback={<p>Loading...</p>}>
                    <GeneralInformation />
                  </Suspense>
                </Tab.Pane>
                <Tab.Pane eventKey={"2"}>
                  <Suspense fallback={<p>Loading...</p>}>
                    <ChangePassword />
                  </Suspense>
                </Tab.Pane>
                <Tab.Pane eventKey={"3"}>
                  <Suspense fallback={<p>Loading...</p>}>
                    <SocialInfo />
                  </Suspense>
                </Tab.Pane>
                <Tab.Pane eventKey={"4"}>
                  <Suspense fallback={<p>Loading...</p>}>
                    <PluginInfo />
                  </Suspense>
                </Tab.Pane>
                {/* <Tab.Pane eventKey={"5"}>
                  <Notifications />
                </Tab.Pane> */}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </>
  );
};

export default Settings;
