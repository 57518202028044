import moment from "moment/moment";
import React, { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { Col, Card, Container, Row, Button, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { BiEdit } from "react-icons/bi";
import { BsPlusCircle, BsTrash } from "react-icons/bs";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import AddUpdatesData from "../../components/Offcanvas/AddUpdatesData";
import { selectUser } from "../../features/authSlice";
import {
  deleteMultiplePosts,
  deletePosts,
  getPostsAndUpdate,
} from "../../Services/adminApi";
import { AiFillMinusCircle } from "react-icons/ai";
import PostsData from "../../components/ExpandableData/PostsData";
import LoadingSpinner from "../../components/LoadingSpinner";

const AddUpdates = () => {
  const notify = (message) => {
    toast(message);
  };
  const [pending, setPending] = useState(true);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { user } = useSelector(selectUser);
  const [showOffcanvas, setshowOffcanvas] = useState(false);
  const handleCloseOffcanvas = () => setshowOffcanvas(false);
  const handleshowOffcanvas = () => setshowOffcanvas(true);
  const [postData, setPostData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isEdit, setIsEdit] = useState({
    id: "",
    title: "",
    slug: "",
    post_url: "",
    update_image: "",
    video_url: "",
    schedule_update: "",
    description: "",
  });

  const [filteredResults, setFilteredResults] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredData = postData.filter((item) => {
        return (
          Object.values(item.post_url)
            .join("")
            .toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          Object.values(item.title)
            .join("")
            .toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          Object.values(item.schedule_update)
            .join("")
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        );
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(postData);
    }
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
    },
    {
      name: "Date",
      selector: (row) => {
        if (row.schedule_update === "0000-00-00")
          return <p className="ms-3 mb-0">-</p>;
        else
          return (
            <p className="mb-0">
              {moment(row.schedule_update).format("DD-MM-YYYY")}
            </p>
          );
      },
    },
    {
      name: "Title",
      selector: (row) => row.title,
    },
    {
      name: "Status",
      selector: (row) => {
        if (+row.status == 1)
          return <p className="text-success fw-bold">Active</p>;
        else return <p className="text-danger fw-bold">Deactive</p>;
      },
    },
    {
      name: "Action",
      selector: (row) => (
        <div>
          <Button
            className="p-1 me-1 bg-success border-white py-0"
            onClick={() => {
              setIsEdit({
                id: row.id,
                title: row.title,
                slug: row.slug,
                post_url: row.post_url,
                update_image: row.update_image,
                video_url: row.video_url,
                schedule_update: row.schedule_update,
                description: row.description,
              });
              handleshowOffcanvas();
            }}>
            <BiEdit className="fs-6" />
          </Button>
          <Button
            onClick={() => handleDelete(row.id)}
            className="p-1 bg-danger border-white py-0">
            <BsTrash className="fs-6" />
          </Button>
        </div>
      ),
    },
  ];

  const handleMultipleDelete = async () => {
    if (window.confirm(`Are you sure you want to delete?`)) {
      const aData = {
        ids: selectedRows.map((item) => item.id),
      };

      const res = await deleteMultiplePosts(aData);
      if (res.status) {
        notify(res.message);
        setRefresh(!refresh);
        setToggleCleared(!toggleCleared);
        setSelectedRows([]);
      } else {
        notify(res.message);
      }
    }
  };
  const handleDelete = async (id) => {
    const res = await deletePosts(id);
    if (res.status) {
      notify(res.message);
      setRefresh(!refresh);
    } else {
      notify(res.message);
    }
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const fetchPostandUpdate = async () => {
    const res = await getPostsAndUpdate(user.login_id || user.id);
    if (res.status) {
      setPostData(res.data);
      setPending(false);
    } else {
      setPostData([]);
    }
  };

  useEffect(() => {
    fetchPostandUpdate();
  }, [showOffcanvas, refresh, toggleCleared]);

  return (
    <>
      <Container fluid className="mt-n5">
        <Row>
          <Col md={12}>
            <Card data-aos={"fade-up"}>
              <Card.Header>
                <div className="header-title w-100 align-items-center flex-wrap-reverse gap-md-0 gap-3 d-flex justify-content-between">
                  <h4 className="card-title">Post & Updates</h4>
                  <Form.Control
                    type="search"
                    className="w-auto ms-auto me-3 search-field"
                    id="search-data"
                    onChange={(e) => searchItems(e.target.value)}
                    placeholder="Search"
                  />
                  <Button
                    onClick={() => {
                      setIsEdit({
                        id: "",
                        title: "",
                        slug: "",
                        post_url: "",
                        update_image: "",
                        video_url: "",
                        schedule_update: "",
                        description: "",
                      });
                      handleshowOffcanvas();
                    }}
                    className="ma-btn-primary flex-grow-1 flex-md-grow-0 btn-shadow">
                    <BsPlusCircle /> Add New
                  </Button>
                  {/* </div> */}
                </div>
              </Card.Header>

              <Card.Body className="p-0">
                <DataTable
                  columns={columns}
                  data={searchInput.length > 1 ? filteredResults : postData}
                  selectableRows
                  progressPending={pending}
                  progressComponent={<LoadingSpinner />}
                  clearSelectedRows={toggleCleared}
                  onSelectedRowsChange={handleRowSelected}
                  expandableRows
                  expandableRowsComponent={PostsData}
                  pagination
                  searchbar
                />
                {selectedRows?.length > 1 && (
                  <div className="d-flex px-3 mb-3 align-items-center justify-content-between">
                    <Button
                      onClick={handleMultipleDelete}
                      size={"sm"}
                      className="ma-btn-primary text-end btn-shadow">
                      <AiFillMinusCircle className="mb-1" /> Delete Selected{" "}
                    </Button>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <AddUpdatesData
        edit={isEdit}
        open={showOffcanvas}
        close={handleCloseOffcanvas}
      />
    </>
  );
};

export default AddUpdates;
