import React, { useState } from "react";
import {
  Button,
  Card,
  Carousel,
  Col,
  Container,
  FloatingLabel,
  Form,
  InputGroup,
  ListGroup,
  Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { login } from "../../features/authSlice";
import { useDispatch } from "react-redux";

const SignOne = () => {
  const dispatch = useDispatch();

  const SignupSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().min(6, "Enter minimum 6 Digit").required("Required"),
  });

  const handleSubmit = (values) => {
    if (values.email == "altafahmad@sartiaglobal.com") {
      dispatch(
        login({
          id: "1",
          name: "Rahul ",
          email: "Rahul@gmail.com ",
        })
      );
    }
  };
  const formmap = [
    {
      id: 1,
      label: "Email Address",
      type: "email",
      name: "email",
    },
    {
      id: 2,
      label: "Password",
      type: "password",
      name: "password",
    },
  ];

  const slide = [
    {
      id: 1,
      class: "first-img",
      bottom: "my-bottom",
      title: (
        <>
          <span className="text-primary">G</span>oogle Panda
        </>
      ),
      text: (
        <>
          {" "}
          Google Panda is a major change to Google's search results ranking
          algorithm that was first released in February 2011. The change aimed
          to lower the rank of "low-quality sites" or "thin sites", in
          particular "content farms", and return higher-quality sites near the
          top of the search results.
        </>
      ),
      img: "https://thewingshield.com/assets/img/panda.gif",
    },
    {
      id: 2,
      class: "first-img2",
      title: (
        <>
          <span className="text-primary">G</span>oogle Penguin
        </>
      ),
      text: (
        <>
          Google Penguin was a codename for a Google algorithm update that was
          first announced on April 24, 2012. The Penguin Update works by
          crawling backlinks on websites and assigning each backlink a
          score.Today, the Penguin update is a part of the core algorithm of the
          Google search engine.
        </>
      ),
      img: "https://www.linkpicture.com/q/ezgif.com-gif-maker-1_29.gif",
    },
    {
      id: 3,
      title: "Hummingbird",
      text: (
        <>
          Hummingbird is the codename given to a significant algorithm change in
          Google Search in 2013. Its name was derived from the speed and
          accuracy of the hummingbird. The change was announced on September 26,
          2013, having already been in use for a month. "Hummingbird" considers
          the context of the different words together, with the goal that pages
          matching the meaning do better, rather than pages matching just a few
          words
        </>
      ),
      img: "https://giffiles.alphacoders.com/868/86811.gif",
    },
    {
      id: 4,
      title: (
        <>
          <span className="text-primary">G</span>oogle Pigeon
        </>
      ),
      text: (
        <>
          Google Pigeon is the code name given to one of Google's local search
          algorithm updates. This update was released on July 24, 2014. Pigeon
          was designed to tie Google's local search algorithm closer to their
          web algorithm and to improve ranking parameters based on distance and
          location.
        </>
      ),
      img: "https://thewingshield.com/assets/img/pigeon.gif",
    },
  ];
  return (
    <body className="form-bg">
      <Container>
        <Row className="justify-content-center align-items-center min-vh-100">
          <Col md={11}>
            <Row className="gx-0">
              <Col md={7}>
                <Card className="h-100 bg-transparent1 d-none d-md-block shadow-sm rounded-0 rounded-start text-white">
                  <Carousel controls={false} fade>
                    {slide.map((slide, id) => (
                      <Carousel.Item key={id} className="position-relative">
                        <img
                          className={slide.class}
                          src={slide.img}
                          alt="First slide"
                        />
                        <Carousel.Caption
                          className={`start-0 end-0 mx-2 text-white text-shadow-1 ${slide.bottom}`}
                        >
                          <h3 className="text-white mb-3 border-bottom d-inline-block pb-2">
                            {slide.title}
                          </h3>
                          <p className="text-light">{slide.text}</p>
                        </Carousel.Caption>
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </Card>
              </Col>
              <Col md={5}>
                <Card className="h-100 px-md-3 rounded-0 flex-row-reverse align-items-center rounded-end">
                  <Card.Body>
                    <h3 className="mb-3 text-shadow-1">New Login</h3>
                    <Formik
                      initialValues={{
                        email: "",
                        password: "",
                      }}
                      validationSchema={SignupSchema}
                      onSubmit={handleSubmit}
                    >
                      {(props) => (
                        <Form
                          onSubmit={props.handleSubmit}
                          className="myform row"
                        >
                          {formmap.map((form, ida) => (
                            <Col md={12}>
                              <FloatingLabel
                                key={ida}
                                controlId="floatingInput"
                                label={form.label}
                                className="mb-3"
                              >
                                <Form.Control
                                  className="p-2"
                                  type={form.type}
                                  placeholder={form.label}
                                  name={form.name}
                                  value={props.values[form.name]}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  isValid={
                                    props.touched[form.name] &&
                                    !props.errors[form.name]
                                  }
                                  isInvalid={
                                    props.touched[form.name] &&
                                    props.errors[form.name]
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  {props.errors[form.name] &&
                                    props.touched[form.name] &&
                                    props.errors[form.name]}
                                </Form.Control.Feedback>
                              </FloatingLabel>
                            </Col>
                          ))}
                          <Form.Group className="mb-3 align-items-center justify-content-between d-md-flex">
                            <Form.Check
                              type="checkbox"
                              id="chk"
                              label="Remember Me"
                            />
                            <Link to="/ForgetPassword">Forget Password</Link>
                          </Form.Group>
                          <Button
                            type="submit"
                            className="mb-3 w-100 btn-shadow"
                            variant="primary"
                          >
                            Sign Up
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </body>
  );
};

export default SignOne;
