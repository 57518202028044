import * as Yup from "yup";

const phoneRegExp = /^[6-9]{1}[0-9]{9}$/;

export const addGeneralSchema = Yup.object().shape({
  user_email: Yup.string().email("Invalid email").required("Email is Required"),
  user_name: Yup.string().required("Name is Required"),
  user_phone: Yup.string()
    .matches(phoneRegExp, "contact number is not valid")
    .typeError("Phone no. must be a number")
    .min(10, "Enter minimum 10 character")
    .required("Phone no. is a Required"),
  expire_date: Yup.string(),
  company_logo: Yup.string(),
});

export const addCredentialSchema = Yup.object().shape({
  v_email: Yup.string().email("Invalid email").required("Email is Required"),
  v_password: Yup.string().required("Password is Required"),
  v_port: Yup.string().required("Port is Required"),
  v_host: Yup.string().required("Host is Required"),
});

export const changePasswordSchema = Yup.object().shape({
  new_password: Yup.string()
    .min(8, "Password must be atleast 8 characters")
    .required("New Password is a Required "),
  re_type_password: Yup.string()
    .oneOf([Yup.ref("new_password"), null], "Passwords must match")
    .required("Confirm Password is a Required"),
});
export const addCompanySchema = Yup.object().shape({
  company_name: Yup.string().required("Company Name is Required"),
  company_address: Yup.string().required("Company Address is Required"),
  company_phone_no: Yup.string()
    .matches(phoneRegExp, "Phone Number is not valid")
    .typeError("Phone no. must be a number")
    .min(10, "Enter minimum 10 character")
    .required("Company Phone no. is a Required"),
  company_profile: Yup.string().required("Company Profile is Required"),
  website_URL: Yup.string().required("Website URL is Required"),
});
export const editUpdatesSchema = Yup.object().shape({
  title: Yup.string().required("Title is Required"),
  slug: Yup.string()
    .required("Slug is Required")
    .matches(
      /^[aA-zZ\s]+$/,
      "Only alphabets,underscore and white spaces are allowed for this field "
    ),
  post_url: Yup.string().required("url is Required"),
  update_image: Yup.mixed(),
  description: Yup.string().required("description is Required"),
  video_url: Yup.string(),
  schedule_update: Yup.string(),
});
export const addUpdatesSchema = editUpdatesSchema.shape({
  update_image: Yup.mixed().required("Image is Required"),
});
export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is Required"),
});

export const otpSchema = Yup.object().shape({
  otp: Yup.number()
    .required("otp is required")
    .min(6, "otp must be at least 6 characters"),
});
export const updatePasswordSchema = Yup.object().shape({
  new_password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is Required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("new_password"), null], "Passwords must match")
    .required("Confirm Password is Required"),
});
export const keywordSchema = Yup.object().shape({
  keyword_url: Yup.string().required("Keyword url is Required"),
  og_url: Yup.string().required("Og url is Required"),
  og_title: Yup.string().required("Og title is Required"),
  twitter_title: Yup.string().required("Twitter title is Required"),
  og_image: Yup.mixed().required("Og image is Required"),
  twitter_image: Yup.mixed().required("Twitter image is Required"),
  og_description: Yup.string().required("Og description is Required"),
  twitter_description: Yup.string().required("Twitter description is Required"),
});
