import React from "react";
import { Col, Card, Container, Row } from "react-bootstrap";

const KnowledgeBase = () => {
  return (
    <>
      <Container fluid className="mt-n5">
        <Row>
          <Col md={12}>
            <Card
              data-aos={"fade-up"}
              className="bg-dark text-shadow-1 text-white knowledge-base1"
            >
              <Card.Img
                className="knowledge-base"
                src="/knowledge_base.jpg"
                alt="Card image"
              />
              <Card.ImgOverlay
                style={{ zIndex: 9 }}
                className="d-flex flex-column justify-content-center p-md-5"
              >
                <h4 className="text-white mb-3">
                  Have Any Questions UnBlem SEO Plugin?
                </h4>
                <p>
                  ePlugin has data sense (AI LSO Based Technology) which Sense
                  all Web Pages Data to manage entire SEO Process Automatically
                  according to page behavior. That functions can be added to
                  your existing website to discovered online your all Web Pages.
                  We provide a new form of high quality automation. Automation
                  is the perfect addition to your digital marketing strategy.
                </p>
              </Card.ImgOverlay>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default KnowledgeBase;
