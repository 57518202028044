import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import InquiriesExpandedData from "../components/ExpandableData/InquiriesExpandedData";
import { getAllInquiries } from "../Services/adminApi";
import { useSelector } from "react-redux";
import { selectUser } from "../features/authSlice";
import LoadingSpinner from "../components/LoadingSpinner";

const Inbox = () => {
  return (
    <>
      <Container fluid className="mt-n5">
        <Row>
          <RecentInquiries />
        </Row>
      </Container>
    </>
  );
};

export default Inbox;

export const RecentInquiries = () => {
  const { user } = useSelector(selectUser);
  const [pending, setPending] = useState(true);
  const [inquiryData, setInquiryData] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredData = inquiryData.filter((item) => {
        return (
          Object.values(item.name)
            .join("")
            .toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          Object.values(item.phone)
            .join("")
            .toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          Object.values(item.email)
            .join("")
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        );
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(inquiryData);
    }
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
    },
  ];
  const getInquiriesData = async () => {
    const iData = {
      id: user.login_id || user.id,
    };

    const res = await getAllInquiries(iData);
    if (res.status) {
      setInquiryData(res.data);
      setPending(false);
    } else {
      setInquiryData([]);
    }
  };
  useEffect(() => {
    getInquiriesData();
  }, []);
  return (
    <Col md={12}>
      <Card data-aos={"fade-up"}>
        <Card.Header>
          <div className="header-title w-100 align-items-center flex-wrap-reverse gap-md-0 gap-3 d-flex justify-content-between">
            <h4 className="card-title">Recent inquiries</h4>
            <Form.Control
              type="search"
              className="w-auto ms-auto me-3 search-field"
              id="search-data"
              onChange={(e) => searchItems(e.target.value)}
              placeholder="Search"
            />
          </div>
        </Card.Header>
        <Card.Body className="p-0">
          <DataTable
            columns={columns}
            data={searchInput.length > 1 ? filteredResults : inquiryData}
            progressPending={pending}
            progressComponent={<LoadingSpinner />}
            pagination
            searchbar
            expandableRows
            expandableRowsComponent={InquiriesExpandedData}
          />
        </Card.Body>
      </Card>
    </Col>
  );
};
