import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'swiper/css';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/css/customizer.min.css';
import './assets/css/hope-ui.min.css';
import App from './App';
import { Provider } from 'react-redux';
import { persistor, store } from './features/store';
import { PersistGate } from 'redux-persist/integration/react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

