import React from "react";
import { Spinner } from "react-bootstrap";

const LoadingSpinner = () => {
  return (
    <Spinner
      animation="border"
      role="status"
      style={{ width: "4rem", height: "4rem", margin: "50px 0px" }}></Spinner>
  );
};

export default LoadingSpinner;
