import moment from "moment/moment";
import React, { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import {
  Col,
  Card,
  Container,
  Row,
  Button,
  Form,
  FloatingLabel,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { BiEdit } from "react-icons/bi";
import {
  BsPatchPlus,
  BsPlusCircle,
  BsPlusCircleFill,
  BsTrash,
} from "react-icons/bs";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import AddUpdatesData from "../../components/Offcanvas/AddUpdatesData";
import { selectUser } from "../../features/authSlice";
import {
  deleteMultiplePosts,
  deletePosts,
  getPostsAndUpdate,
} from "../../Services/adminApi";
import { AiFillMinusCircle } from "react-icons/ai";
import PostsData from "../../components/ExpandableData/PostsData";
import LoadingSpinner from "../../components/LoadingSpinner";

const FooterCms = () => {
  const notify = (message) => {
    toast(message);
  };
  const [pending, setPending] = useState(true);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { user } = useSelector(selectUser);
  const [showOffcanvas, setshowOffcanvas] = useState(false);
  const handleCloseOffcanvas = () => setshowOffcanvas(false);
  const handleshowOffcanvas = () => setshowOffcanvas(true);
  const [postData, setPostData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isEdit, setIsEdit] = useState({
    id: "",
    title: "",
    slug: "",
    post_url: "",
    update_image: "",
    video_url: "",
    schedule_update: "",
    description: "",
  });

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
    },
    {
      name: "Image",
      selector: (row) => (
        <div className="py-2">
          <img
            src="https://dunkez.com/images/portfolio-1.jpg"
            width="40"
            class="rounded"
            alt="Featured"
          />
        </div>
      ),
    },
    {
      name: "Date",
      selector: (row) => {
        if (row.schedule_update === "0000-00-00")
          return <p className="ms-3">-</p>;
        else return <p>{moment(row.schedule_update).format("DD-MMM-YYYY")}</p>;
      },
    },
    {
      name: "Title",
      selector: (row) => row.title,
    },
    {
      name: "Description",
      selector: (row) => row.title,
    },
    {
      name: "Action",
      selector: (row) => (
        <div>
          <Button
            className="p-1 me-1 bg-success border-white py-0"
            onClick={() => {
              setIsEdit({
                id: row.id,
                title: row.title,
                slug: row.slug,
                post_url: row.post_url,
                update_image: row.update_image,
                video_url: row.video_url,
                schedule_update: row.schedule_update,
                description: row.description,
              });
              handleshowOffcanvas();
            }}
          >
            <BiEdit className="fs-6" />
          </Button>
          <Button
            onClick={() => handleDelete(row.id)}
            className="p-1 bg-danger border-white py-0"
          >
            <BsTrash className="fs-6" />
          </Button>
        </div>
      ),
    },
  ];

  const handleMultipleDelete = async () => {
    if (
      window.confirm(
        `Are you sure you want to delete:\r ${selectedRows.map(
          (r) => r.title
        )}?`
      )
    ) {
      const aData = {
        ids: selectedRows.map((item) => item.id),
      };

      const res = await deleteMultiplePosts(aData);
      if (res.status) {
        notify(res.message);
        setRefresh(!refresh);
        setToggleCleared(!toggleCleared);
        setSelectedRows([]);
      } else {
        notify(res.message);
      }
    }
  };

  const handleDelete = async (id) => {
    const res = await deletePosts(id);
    if (res.status) {
      notify(res.message);
      setRefresh(!refresh);
    } else {
      notify(res.message);
    }
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const fetchPostandUpdate = async () => {
    const res = await getPostsAndUpdate(user.login_id || user.id);
    if (res.status) {
      setPostData(res.data);
      setPending(false);
    } else {
      setPostData([]);
    }
  };

  useEffect(() => {
    fetchPostandUpdate();
  }, [showOffcanvas, refresh, toggleCleared]);

  const [count, setCount] = useState(0);
  const AddedElement = () => (
    <>
      <FloatingLabel controlId="face2" label="Nav Items" className="mb-3">
        <Form.Control
          type="url"
          className="border-bottom-0"
          defaultValue="Title"
        />
        <Form.Control type="url" defaultValue="Type Url..." />
      </FloatingLabel>
    </>
  );

  return (
    <>
      <Row>
        <Col md={12}>
          <Card data-aos={"fade-up"} data-aos-delay={200}>
            <Card.Header className="pb-0">
              <Card.Title>-- Manage Footer Content --</Card.Title>
            </Card.Header>
            <Card.Body>
              <Form>
                <Row className="align-item-end">
                  <Col>
                    <Form.Label>Section 1</Form.Label>
                    <FloatingLabel
                      controlId="one"
                      label="Title"
                      className="mb-3"
                    >
                      <Form.Control type="text" defaultValue="Get In Touch" />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="area"
                      label="Address"
                      className="mb-3"
                    >
                      <Form.Control
                        as="textarea"
                        defaultValue="123 Street, New York, USA"
                        style={{ height: "100px" }}
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="number"
                      label="Phone Number"
                      className="mb-3"
                    >
                      <Form.Control
                        type="number"
                        defaultValue="+012 345 67890"
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="email"
                      label="Email Address"
                      className="mb-3"
                    >
                      <Form.Control
                        type="email"
                        defaultValue="info@example.com"
                      />
                    </FloatingLabel>
                  </Col>
                  <Col>
                    <Form.Label>Section 2</Form.Label>
                    <FloatingLabel
                      controlId="sec2"
                      label="Title"
                      className="mb-3"
                    >
                      <Form.Control type="text" defaultValue="Popular Link" />
                    </FloatingLabel>
                    <div className="my-scroll">
                      <FloatingLabel
                        controlId="face2"
                        label="Nav Items"
                        className="mb-3"
                      >
                        <Form.Control
                          type="url"
                          className="border-bottom-0"
                          defaultValue="About Us"
                        />
                        <Form.Control
                          type="url"
                          defaultValue="https://dunkez.com/about"
                        />
                      </FloatingLabel>
                      <FloatingLabel
                        controlId="face2"
                        label="Nav Items"
                        className="mb-3"
                      >
                        <Form.Control
                          type="url"
                          className="border-bottom-0"
                          defaultValue="Contact Us"
                        />
                        <Form.Control
                          type="url"
                          defaultValue="https://dunkez.com/contact"
                        />
                      </FloatingLabel>
                      <FloatingLabel
                        controlId="face2"
                        label="Nav Items"
                        className="mb-3"
                      >
                        <Form.Control
                          type="url"
                          className="border-bottom-0"
                          defaultValue="Privacy Policy"
                        />
                        <Form.Control
                          type="url"
                          defaultValue="https://dunkez.com/privacypolicy"
                        />
                      </FloatingLabel>
                      <FloatingLabel
                        controlId="face2"
                        label="Nav Items"
                        className="mb-3"
                      >
                        <Form.Control
                          type="url"
                          className="border-bottom-0"
                          defaultValue="Terms & Condition"
                        />
                        <Form.Control
                          type="url"
                          defaultValue="https://dunkez.com/termscondition"
                        />
                      </FloatingLabel>
                      <FloatingLabel
                        controlId="face2"
                        label="Nav Items"
                        className="mb-3"
                      >
                        <Form.Control
                          type="url"
                          className="border-bottom-0"
                          defaultValue="Blog"
                        />
                        <Form.Control
                          type="url"
                          defaultValue="https://dunkez.com/blog"
                        />
                      </FloatingLabel>
                      {[...Array(count)].map((_, i) => (
                        <AddedElement key={i} />
                      ))}
                      <div className="text-end fs-3 a-btn-primary">
                        <BsPlusCircleFill onClick={() => setCount(count + 1)} />
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <Form.Label>Section 3</Form.Label>
                    <FloatingLabel
                      controlId="sec2"
                      label="Title"
                      className="mb-3"
                    >
                      <Form.Control type="text" defaultValue="Newsletter" />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="se2"
                      label="Address"
                      className="mb-3"
                    >
                      <Form.Control
                        as="textarea"
                        defaultValue="Lorem ipsum dolor sit amet elit. Phasellus nec pretium mi. Curabitur facilisis ornare velit non vulpu"
                        style={{ height: "100px" }}
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="se2"
                      label="Copyright"
                      className="mb-3"
                    >
                      <Form.Control
                        as="textarea"
                        defaultValue="© UnBlem, All Right Reserved. Designed By UnBlem"
                        style={{ height: "100px" }}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
                <div className="text-end">
                  <Button
                    variant="primary"
                    type={"submit"}
                    className="ma-btn-primary"
                  >
                    Save Change
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <AddUpdatesData
        edit={isEdit}
        open={showOffcanvas}
        close={handleCloseOffcanvas}
      />
    </>
  );
};

export default FooterCms;
