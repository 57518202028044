import React, { useState } from "react";
import {
  Col,
  Button,
  Card,
  Container,
  Row,
  Table,
  Spinner,
} from "react-bootstrap";
import { BsDownload } from "react-icons/bs";
import { getGeneratesitemap } from "../../Services/adminApi";
import { toast } from "react-toastify";

const OwnerVerification = () => {
  const [loading, setLoading] = useState(false);

  const downloadFile = async () => {
    const filename = "google19bfd697878ab352.html";
    const fileUrl = "/google19bfd697878ab352.html";
    const element = document.createElement("a");
    element.setAttribute("href", fileUrl);
    element.setAttribute("download", filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
  };

  const downloadSitemap = async () => {
    setLoading(true);
    const res = await getGeneratesitemap();
    if (res.status) {
      const pdfData = await fetch(
        `${process.env.REACT_APP_API_URL}/${res.data}`
      ).then((response) => response.blob());
      const blobUrl = URL.createObjectURL(pdfData);
      const element = document.createElement("a");
      element.href = blobUrl;
      element.download = "sitemap.xml";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      URL.revokeObjectURL(blobUrl);
    } else {
      toast.error(res.message);
    }
    setLoading(false);
  };
  return (
    <>
      <Container fluid className="mt-n5">
        <Row className="g-4">
          <Col md={8}>
            <Card data-aos={"fade-up"} className="h-100">
              <Card.Header>
                <div className="header-title w-100 align-items-center flex-wrap-reverse gap-md-0 gap-3 d-flex justify-content-between">
                  <h4 className="card-title">Verify Property</h4>
                </div>
              </Card.Header>
              <Card.Body className="pt-2">
                <Table responsive striped bordered hover>
                  <tbody>
                    <tr>
                      <th className="fw-bold text-black">HTML File</th>
                      <td>
                        <code>Upload an HTML File to your website</code>
                      </td>
                    </tr>
                    <tr>
                      <th>1. Download the file:</th>
                      <td>
                        <code>
                          <Button
                            className="ma-btn-primary"
                            onClick={() => downloadFile()}
                          >
                            <BsDownload /> googlee6db94d840d16996.html
                          </Button>
                        </code>
                      </td>
                    </tr>
                    <tr>
                      <th>2. Upload to:</th>
                      <td>
                        <code>http://unblem.sartiaglobal.com</code>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <p className="mt-4 mt-md-0">
                  To stay verified, don't remove the file, even after
                  verification succeeds.
                </p>
                <div className="d-md-flex d-grid gap-3">
                  <a
                    href="https://pagespeed.web.dev"
                    target="blank"
                    className="btn btn-outline-primary ma-btn-primary"
                  >
                    Check Performance
                  </a>
                  <Button className="ma-btn-primary">Verify</Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card data-aos={"fade-up"} className="h-100">
              <Card.Header>
                <div className="header-title w-100 align-items-center flex-wrap-reverse gap-md-0 gap-3 d-flex justify-content-between">
                  <h4 className="card-title">Action</h4>
                </div>
              </Card.Header>
              <Card.Body className="pt-2">
                <div className="d-grid gap-2">
                  <Button
                    variant="primary"
                    className="ma-btn-primary"
                    onClick={downloadSitemap}
                  >
                    {loading ? (
                      <>
                        <Spinner /> Generating...
                      </>
                    ) : (
                      "Generate SiteMap"
                    )}
                  </Button>
                  <small>
                    Sitemap:<u> http://unblem.sartiaglobal.com</u>
                  </small>
                  <span>
                    <bold className="text-danger">Note:-</bold>
                    <small className="blink_me">
                      You need to add your sitemap location to your robots.txt
                      file.
                    </small>
                  </span>
                  {/* <Button variant="light" className="ma-btn-primary">Search Appearance</Button>
                  <Button variant="secondary" className="ma-btn-primary">Site Links</Button>
                  <Button variant="success" className="ma-btn-primary">Download CSV</Button> */}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OwnerVerification;
