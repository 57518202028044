import axios from "axios";

const customApi = axios.create({
	baseURL: process.env.REACT_APP_API_URL_LIVE,
	headers: {
		email: process.env.REACT_APP_API_EMAIL,
		password: process.env.REACT_APP_API_PASSWORD,
	},
});
export const loginApi = async (values) => {
	try {
		const { data } = await customApi.post(`/webapi/pluginlogin`, values);
		return data;
	} catch (error) {
		return error.response.data;
	}
};
export const resetPasswordApi = async (values) => {
	try {
		const { data } = await customApi.post(
			`/webapi/resetpluginpassword`,
			values
		);
		return data;
	} catch (error) {
		return error.response.data;
	}
};
