import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { BsFillEyeSlashFill, BsFillEyeFill } from "react-icons/bs";

export const PasswordInput = ({ placeholder, rightIcon, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="d-flex align-items-center">
      <Form.Control
        placeholder={placeholder}
        aria-label={placeholder}
        aria-describedby="basic-addon2"
        type={showPassword ? "text" : "password"}
        {...props}
      />
      <span
        style={{
          marginLeft: props.isInvalid ? "-55px" : "-30px",
        }}
        className="cursor-pointer"
        onClick={toggleShowPassword}
      >
        {showPassword ? <BsFillEyeFill /> : <BsFillEyeSlashFill />}
      </span>
    </div>
  );
};
