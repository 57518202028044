import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { useEffect } from "react";
import Home from "./pages/Home";
import Inbox from "./pages/Inbox";
import Settings from "./pages/Settings";
import NoPage from "./pages/Authentication/NoPage";
import SignOne from "./pages/Authentication/SignOne";
import Profile from "./pages/Authentication/Profile";
import SignIn from "./pages/Authentication/SignIn";
import { PublicRoutes, RequireAuth } from "./components/AuthChecker";
import TestingPage from "./pages/TestingPage";
import PluginSetup from "./pages/Setup&Installation/PluginSetup";
import KnowledgeBase from "./pages/KnowledgeBase";
import AddUpdates from "./pages/Post&Updates/AddUpdates";
import AddKeywords from "./pages/ManageKeywords/AddKeywords";
import OwnerVerification from "./pages/eSearchConsole/OwnerVerification";
import Coverage from "./pages/eSearchConsole/Coverage";
import OTP from "./pages/Authentication/OTP";
import SentEmail from "./pages/Authentication/SentEmail";
import ForgetPassword from "./pages/Authentication/ForgetPassword";
import AOS from "aos";
import "aos/dist/aos.css";
import CmsSetting from "./pages/CmsSetting";
import Notifications from "./pages/Notifications";
import Messages from "./pages/Messages";

const App = () => {
  useEffect(() => {
    AOS.init({
      once: true,
    });
    AOS.refresh();
  }, []);

  AOS.init();
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PublicRoutes />}>
          <Route path="/" element={<SignIn />} />
          <Route path="/ForgetPassword" element={<ForgetPassword />} />
          <Route path="/OTP" element={<OTP />} />
          <Route path="/SentEmail" element={<SentEmail />} />
        </Route>
        <Route element={<RequireAuth />}>
          <Route path="/dashboard" element={<Home />} />
          <Route path="/Inbox" element={<Inbox />} />
          <Route path="/Settings" element={<Settings />} />
          <Route path="/CmsSetting" element={<CmsSetting />} />
          <Route path="/PluginSetup" element={<PluginSetup />} />
          <Route path="/KnowledgeBase" element={<KnowledgeBase />} />
          <Route path="/AddUpdates" element={<AddUpdates />} />
          <Route path="/AddBlogs" element={<AddUpdates />} />
          <Route path="/AddKeywords" element={<AddKeywords />} />
          <Route path="/OwnerVerification" element={<OwnerVerification />} />
          <Route path="/Coverage" element={<Coverage />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/TestingPage" element={<TestingPage />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/messages" element={<Messages />} />
        </Route>
        <Route path="*" element={<NoPage />} />
        <Route path="/SignOne" element={<SignOne />} />
      </Routes>
    </BrowserRouter>
  );
};
export default App;
