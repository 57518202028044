import { useState } from "react";
import { Outlet } from "react-router-dom";
import Footerjs from "../Footer";
import Navbarjs from "../Navbar";
import Sidebarjs from "../Sidebar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Layout = () => {
  const [aSide, aSideactive] = useState(true);
  const ToggleClass = () => {
    aSideactive(!aSide);
  };
  return (
    <>
      <Sidebarjs sidebaropen={aSide} toggle={ToggleClass} />
      <main className="main-content">
        <Navbarjs toggle={ToggleClass} />
        <ToastContainer />
        <Outlet />
        <Footerjs />
      </main>
    </>
  );
};

export default Layout;
