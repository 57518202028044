import { Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Col, Form, Offcanvas } from "react-bootstrap";
import { BiEdit } from "react-icons/bi";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/authSlice";
import {
  addPostsAndUpdates,
  getUpdateUrl,
  updatePostsAndUpdates,
} from "../../Services/adminApi";
import { Field, Formik } from "formik";
import {
  addUpdatesSchema,
  editUpdatesSchema,
} from "../../utils/validationSchemas";
import { Typeahead } from "react-bootstrap-typeahead";

const AddUpdatesData = ({ open, close, edit }) => {
  const notify = (message) => {
    toast(message);
  };
  const [postAndUpdates, setPostAndUpdates] = useState([]);
  const { user } = useSelector(selectUser);
  const [selectedImage, setSelectedImage] = useState(null);
  const handlePostsData = async (values, setSubmitting) => {
    const bodyFormData = new FormData();
    bodyFormData.set("title", values.title);
    bodyFormData.set("slug", values.slug);
    bodyFormData.set("post_url", values.post_url);
    bodyFormData.set("description", values.description);
    bodyFormData.set("video_url", values.video_url);
    bodyFormData.set("schedule_update", values.schedule_update);
    bodyFormData.set(
      "update_image",
      selectedImage ? selectedImage : edit.update_image
    );
    if (edit.schedule_update) {
      bodyFormData.set("id", edit.id);
      bodyFormData.set("old_update_image", edit.update_image);
    } else {
      bodyFormData.set("created_by", user.login_id || user.id);
    }

    const res = edit.schedule_update
      ? await updatePostsAndUpdates(bodyFormData)
      : await addPostsAndUpdates(bodyFormData);
    if (res.status) {
      notify(res.message);
      close();
    } else {
      notify(res.message);
    }
    setSubmitting(false);
  };
  const fetchData = async () => {
    const res = await getUpdateUrl(user.login_id || user.id);
    if (res.status) {
      setPostAndUpdates(res.data);
    } else {
      setPostAndUpdates([]);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Offcanvas
        show={open}
        onHide={close}
        placement="end"
        className="myOffcanvas rounded m-md-3 my-3 overflow-hidden">
        <Offcanvas.Header className="bg-soft-primary" closeButton>
          <Offcanvas.Title>
            {edit.schedule_update ? "Edit" : "New"} Update
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Formik
            validationSchema={
              edit.schedule_update ? editUpdatesSchema : addUpdatesSchema
            }
            enableReinitialize={true}
            initialValues={{
              title: edit.title,
              slug: edit.slug,
              post_url: edit.post_url,
              update_image: "",
              description: edit.description,
              video_url: edit.video_url,
              schedule_update: edit.schedule_update,
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              handlePostsData(values, setSubmitting, resetForm);
            }}>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form
                className="form-horizontal"
                noValidate
                onSubmit={handleSubmit}>
                <Form.Group className="mb-3 row">
                  <Form.Label className="col-md-3 align-self-center mb-0">
                    Title <span className="text-danger">*</span>
                  </Form.Label>

                  <Form.Control
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title}
                    name={"title"}
                    type="text"
                    className="ms-3 my-2"
                    style={{ width: "570px" }}
                    placeholder="Enter Title"
                  />

                  <small className="text-danger">
                    {errors.title && touched.title && errors.title}
                  </small>
                </Form.Group>
                <Form.Group className="mb-3 row">
                  <Form.Label className="col-md-3 align-self-center mb-0">
                    Slug <span className="text-danger">*</span>
                  </Form.Label>

                  <Form.Control
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.slug}
                    name={"slug"}
                    type="text"
                    className="ms-3 my-2"
                    style={{ width: "570px" }}
                    placeholder="Enter Slug"
                  />

                  <small className="text-danger">
                    {errors.slug && touched.slug && errors.slug}
                  </small>
                </Form.Group>

                <Form.Group className="mb-4 row">
                  <Form.Label className="col-md-4 align-self-center mb-0">
                    Post Update url <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Form.Select
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.post_url}
                    name={"post_url"}
                    className="ms-3 my-2"
                    style={{ width: "570px" }}
                    aria-label="Default select example">
                    <option>--Select--</option>
                    {postAndUpdates.map((ele) => (
                      <option key={ele.id}>{ele.url}</option>
                    ))}
                  </Form.Select>
                  <small className="text-danger">
                    {errors.post_url && touched.post_url && errors.post_url}
                  </small>
                </Form.Group>
                <Form.Group className="mb-4 row">
                  <Form.Label className="col-md-2 align-self-center mb-0">
                    Image<span className="text-danger">*</span>
                  </Form.Label>
                  <Col md={12}>
                    <div className="d-flex">
                      {edit.schedule_update ? (
                        <>
                          <img
                            src={
                              selectedImage
                                ? URL.createObjectURL(selectedImage)
                                : edit.update_image
                            }
                            style={{
                              objectFit: "cover",
                              width: "200px",
                            }}
                          />
                          <BiEdit
                            className="fs-3 position-relative w-100 p-0"
                            style={{ left: "-13px" }}></BiEdit>
                          <Form.Control
                            onChange={(e) => {
                              handleChange(e);
                              setSelectedImage(e.target.files[0]);
                            }}
                            onBlur={handleBlur}
                            className="position-relative opacity-0 h-25 w-50"
                            name="update_image"
                            type="file"
                            style={{ left: "-180px", objectFit: "fill" }}
                          />
                        </>
                      ) : (
                        <Form.Control
                          onChange={(e) => {
                            handleChange(e);
                            setSelectedImage(e.target.files[0]);
                          }}
                          onBlur={handleBlur}
                          name="update_image"
                          type="file"
                          style={{ left: "-180px", objectFit: "fill" }}
                        />
                      )}
                    </div>
                    <small className="text-danger">
                      {errors.update_image &&
                        touched.update_image &&
                        errors.update_image}
                    </small>
                  </Col>
                </Form.Group>
                <Form.Group className="mb-3 row">
                  <Form.Label className="col-md-4 align-self-center mb-0">
                    Youtube Video Link
                  </Form.Label>

                  <Form.Control
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.video_url}
                    name={"video_url"}
                    className="ms-3 my-2"
                    style={{ width: "570px" }}
                  />
                </Form.Group>
                <Form.Group className="mb-3 row">
                  <Form.Label className="col-md-4 align-self-center mb-0">
                    Description <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Field name="description">
                    {({ field }) => (
                      <ReactQuill
                        value={field.value}
                        onChange={field.onChange(field.name)}
                      />
                    )}
                  </Field>
                  <small className="text-danger">
                    {errors.description &&
                      touched.description &&
                      errors.description}
                  </small>
                </Form.Group>
                <Form.Group className="mb-3 row">
                  <Form.Label className="col-md-4 align-self-center mb-0">
                    Schedule Updates
                  </Form.Label>

                  <Form.Control
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.schedule_update}
                    name={"schedule_update"}
                    type="date"
                    className="ms-3 my-2"
                    style={{ width: "570px" }}
                  />
                </Form.Group>
                <hr />
                <Button
                  variant="primary"
                  type={"submit"}
                  className="w-100 ma-btn-primary">
                  {edit.schedule_update ? "Save Changes" : "Save"}
                </Button>
              </Form>
            )}
          </Formik>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default AddUpdatesData;
